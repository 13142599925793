import Slider from 'react-slick';
import styled from 'styled-components';

export const TournamentBody = styled.div`
  width: 100vw;
  height: calc(100vh - 62px);
  overflow: auto;
  background-color: #f4f0f0;
`;

export const StyledSlider = styled(Slider)`
  .slick-slider,
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: auto;
  }
  .slick-slider {
    overflow: hidden;
  }
  .slick-prev {
    position: absolute;
    top: 20px;
    left: 6% !important;
    z-index: 1;
  }
  .slick-next {
    position: absolute;
    top: 20px;
    right: 6% !important;
    z-index: 1;
  }

  .slick-prev:before,
  .slick-next:before {
    color: rgb(16, 43, 83);
    font-size: 44px;
  }

  .slick-disabled {
    display: none !important;
    cursor: none;
  }

  @media all and (max-width: 768px) {
    .slick-prev:before,
    .slick-next:before {
      display: none;
    }
  }
  .slick-disabled {
    display: none !important;
    cursor: none;
  }
`;
