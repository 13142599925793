import { Backdrop, Fade, Modal } from '@mui/material';
import { ReactPortal } from '@world-cup-client/ui';
import { Close as CloseIcon } from '@mui/icons-material';
import useWindowResize from 'shared/hooks/useWindowResize.js';
import * as S from './OutrightModal.styles.js';
import OutrightBackground from '../../images/outright-background.png';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TournamentContext } from '../../Tournament/contexts/TournamentContext.jsx';
import { language } from '../../state/language.js';

const OutrightModal = ({ isOpen, setIsOpen }) => {
  const { state, odds, gotoBetslip } = useContext(TournamentContext);
  const { isMobile } = useWindowResize();
  const navigate = useNavigate();

  const [currentFinalMatch, setCurrentFinalMatch] = useState(null);

  const onClose = () => {
    setIsOpen(false);
    navigate('/');
  };

  const rounds = state?.tournament?.rounds;
  const finalRound = useMemo(
    () => rounds?.find((round) => round?.name === 'Final'),
    [rounds]
  );

  useEffect(() => {
    const selectedWinnerTeamId =
      currentFinalMatch?.selectedResult === 'home_win'
        ? currentFinalMatch?.selectedHomeTeamId
        : currentFinalMatch?.selectedAwayTeamId;
    const teamName = state?.tournament?.teams?.filter(
      (t) => t.id === selectedWinnerTeamId
    )[0]?.name;
    const selectedOutcome = odds?.market?.possibleOutcomes?.filter(
      (o) => +o.teamId === +selectedWinnerTeamId
    )[0];
    setCurrentFinalMatch({
      ...finalRound?.matches[0],
      selectedWinnerTeam: {
        id: selectedWinnerTeamId,
        name: teamName,
        outcome: selectedOutcome,
      },
    });
  }, [
    currentFinalMatch?.selectedAwayTeamId,
    currentFinalMatch?.selectedHomeTeamId,
    currentFinalMatch?.selectedResult,
    currentFinalMatch?.selectedWinnerTeam?.id,
    finalRound?.matches,
    odds?.market?.possibleOutcomes,
    state?.tournament?.teams,
  ]);

  const gotoBetslipHandler = async () => {
    try {
      const data = await gotoBetslip(
        currentFinalMatch?.selectedWinnerTeam?.outcome?.id
      );
      if (data?.urlForRedirect) {
        window.open(data.urlForRedirect, '_blank');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ReactPortal wrapperId="outright-modal">
      <Modal
        disablePortal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <S.Paper
            $isMobile={isMobile}
            backImg={OutrightBackground}
            elevation={3}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 2000,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.86) 68.23%)',
              }}
            >
              <S.ExitBtn onClick={onClose}>
                <CloseIcon />
              </S.ExitBtn>
              <img
                style={{
                  position: 'absolute',
                  visibility: 'hidden',
                }}
                src={OutrightBackground}
                alt=""
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <S.Title>
                  {language?.modals?.outright?.mainTitle1} <br />
                  {language?.modals?.outright?.mainTitle2}
                </S.Title>
                <S.Content>
                  <S.Description>
                    {currentFinalMatch?.selectedWinnerTeam?.name}{' '}
                    {language?.modals?.outright?.desc}
                  </S.Description>
                  <S.BetBox>
                    @ {currentFinalMatch?.selectedWinnerTeam?.outcome?.odds}
                  </S.BetBox>
                  <S.SubmitBtn
                    // disabled={}
                    onClick={async () => {
                      await gotoBetslipHandler();
                      onClose();
                    }}
                  >
                    {language?.modals?.outright?.submitBtn}
                  </S.SubmitBtn>
                </S.Content>
              </div>
            </div>
          </S.Paper>
        </Fade>
      </Modal>
    </ReactPortal>
  );
};

export default OutrightModal;
