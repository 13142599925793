import React from 'react';
import Grid from '@mui/material/Grid';
import B from '@mui/material/Button';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import tooltip from './images/Question-Mark.png';
import { language } from '../state/language';

export default function TriggersTooltips({ fromGroups }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const longText = (
    <div>
      <p>{language?.components?.howToPlay?.firstStep}</p>
      <p>{language?.components?.howToPlay?.secondStep}</p>
      <p>{language?.components?.howToPlay?.thirdStep}</p>
      <p>{language?.components?.howToPlay?.fourthStep}</p>
      <p>{language?.components?.howToPlay?.fifthStep}</p>
    </div>
  );

  return (
    <div style={!fromGroups ? { display: 'none' } : { zIndex: 99999 }}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={longText}
          >
            <Button onClose={handleTooltipClose} onClick={handleTooltipOpen}>
              <div className="d-flex">
                <span
                  className="howtoplayLink__headLine"
                  style={{ fontWeight: 'bolder' }}
                >
                  {language?.pages?.group?.howToPlay?.title}
                  <img
                    src={tooltip}
                    style={{
                      display: 'inline',
                      width: 20,
                      borderRadius: '50%',
                    }}
                    alt="tooltip"
                  />
                </span>
              </div>
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}
