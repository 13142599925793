import styled from 'styled-components';

export const Button = styled.button`
	width: ${(props) => (props.width ? props.width : '5.7rem')};
	height: ${(props) => (props.height ? props.height : '2.4rem')};
	display: flex;
	justify-content: ${(props) => (props.icon ? 'space-between' : 'center')};
	align-items: center;
	padding: 0 1rem;
	color: ${(props) => (props.toggle ? 'rgb(53, 61, 73, 0.2)' : props.textColor)};
	border-radius: calc(2.4rem / 2);
	border: ${({ disabled }) => (disabled ? 'unset' : '1px solid #d7d8db')};
	user-select: none;
	background-color: ${(props) => (props.toggle ? 'white' : props.bgColor)};
	white-space: nowrap;
`;
