import TournamentBody from '../TournamentBody/TournamentBody';
import * as S from './TournamentContainer.styles';

export const TournamentContainer = ({ showLeaderboard }) => {
  return (
    <S.TournamentContainer>
      <S.Body>
        <TournamentBody />
      </S.Body>
    </S.TournamentContainer>
  );
};
export default TournamentContainer;
