import * as S from './TournamentBody.styles';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowResize } from 'shared/hooks';
import { setStageHandler } from 'shared/store/global/globalActions';
import GroupStage from '../GroupStage/GroupStage';
import { useMediaQuery } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const clickDelay = 620;

export const TournamentBody = () => {
  // const { setStageSize, setStageIndex } = useSelector(
  //   ({ tournament }) => tournament
  // );
  const dispatch = useDispatch();
  const { width, isMobile } = useWindowResize();
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const [disabled, setDisabled] = useState(false);
  const tourBodyRef = useRef(null);
  const sliderRef = useRef(null);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;

    const click = async () => {
      onClick();
      setDisabled(true);
      await delay(clickDelay);
      setDisabled(false);
    };

    return (
      <button
        disabled={disabled}
        className={className}
        onClick={click}
      ></button>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    const click = async () => {
      onClick();
      setDisabled(true);
      await delay(clickDelay);
      setDisabled(false);
    };

    return (
      <button
        disabled={disabled}
        className={className}
        onClick={click}
      ></button>
    );
  };
  const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: clickDelay,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: isDesktop ? false : true,
    centerMode: isDesktop ? true : false,
    draggable: false,
    adaptiveHeight: true,
    nextArrow: isDesktop ? <SampleNextArrow /> : null,
    prevArrow: isDesktop ? <SamplePrevArrow /> : null,

    onSwipe: (dir) => {
      document.activeElement.blur();
    },
    beforeChange: (oldIndex, newIndex) => {
      dispatch(
        setStageHandler({
          index: newIndex + 1,
          size: ['80rem', '68rem', '35rem', '30rem', '40rem'][newIndex],
        })
      );
    },
  };

  return (
    <div id="topContainer">
      <S.TournamentBody id="scrollableTournamentBody" ref={tourBodyRef}>
        <div>
          <S.StyledSlider
            {...sliderSettings}
            ref={sliderRef}
            $isMobile={isMobile}
          >
            <div index={1}>
              <GroupStage topContainerRef={tourBodyRef} />
            </div>
            <div index={2}>{/* <RoundOfSixteen /> */}</div>
            <div index={3}>{/* <QuarterFinals /> */}</div>
            <div index={4}>{/* <SemiFinals /> */}</div>
            <div index={5}>{/* <Final sliderRef={sliderRef} /> */}</div>
          </S.StyledSlider>
        </div>
      </S.TournamentBody>
    </div>
  );
};

export default TournamentBody;
