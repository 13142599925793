import * as S from './TournamentLeaderboard.styles';
import {
  FirstMedal,
  SecondMedal,
  ThirdMedal,
  RankDown,
  RankUp,
} from 'shared/assets/Decoration';
import { useEffect, useMemo, useRef, useState } from 'react';
import useWindowResize from 'shared/hooks/useWindowResize';
import { PeriodTypeEnum } from 'shared/enums/tournament-period-type';
import { BackArrow } from 'shared/assets/images/Arrows';
import { Col } from 'react-bootstrap';
import { AppSpinner } from '@world-cup-client/ui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const BASE_URL = process.env.NX_baseUrl;

export const TournamentLeaderboard = ({
  pickerColor,
  headerColor,
  containerBgColor,
  styledRowBgColor,
  pickersWrapperColor,
  pickersWrapperBgColor,
  greyTitle,
  styledHr,
  headerBgColor,
  headerTitleColor,
  linkColor,
  periodDisplayNameColor,
  leaderBoardTableBgColor,
  rankingColor,
  pointsColor,
  nicknameColor,
  usersRankRowBorderColor,
}) => {
  const token = sessionStorage.getItem('token');
  // hooks
  const navigate = useNavigate();
  const { isMobile } = useWindowResize();
  const pickerRef = useRef(null); //the current opened picker

  // states
  const [leaderboardController, setLeaderboardController] = useState({
    clientId: process.env.NX_ClientId,
    tournamentId: 77,
    period: 'GROUP',
    type: 'GROUP',
  });
  const [leaderboard, setLeaderboard] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [periodDisplayName, setPeriodDisplayName] = useState('Fase De Grupos');
  const [selectedPicker, setSelectedPicker] = useState('GROUP');
  // const leaderboardParams = useMemo(
  //   () => ({
  //     tournamentId: 77,
  //     type: leaderboardController,
  //   }),
  //   [leaderboardController]
  // );

  // constants
  const pickers = [
    {
      displayName: 'Fase De Grupos',
      name: PeriodTypeEnum.GROUP,
      hasPicker: false,
    },
    {
      displayName: 'Torneo',
      name: PeriodTypeEnum.TOURNAMENT,
      hasPicker: false,
    },
    {
      displayName: 'Knockout',
      name: PeriodTypeEnum.KNOCKOUT,
      hasPicker: false,
    },
  ];

  // functions

  const getMedalImg = (rank) => {
    switch (rank) {
      case 1:
        return FirstMedal;
      case 2:
        return SecondMedal;
      case 3:
        return ThirdMedal;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${BASE_URL}/api/get-tournament-leaderboard`,
          {
            headers: {
              'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
              'jwt-token': token,
            },
            params: leaderboardController,
          }
        );
        if (data) {
          setLeaderboard(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchLeaderboard();
  }, [leaderboardController, token]);

  // jsx
  return (
    <>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1.5rem',
          backgroundColor: headerBgColor ? headerBgColor : 'white',
          minHeight: '62px',
          // borderBottom: '1px solid #2b2b2b',
          boxShadow: '0px 0 7px 0 rgba(0, 0, 0, 0.85)',
          padding: '0px 20px',
          position: 'relative',
          whiteSpace: 'nowrap',
          zIndex: 1300,
        }}
      >
        <div style={{ flex: 2 }}>
          <span
            style={{
              color: headerTitleColor ? headerTitleColor : 'black',
              fontSize: 18,
              fontWeight: '600',
            }}
          >
            WORLD CUP 2022
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Link
            to={'/'}
            style={{
              color: linkColor ? linkColor : 'black',
              fontSize: 18,
              fontWeight: '600',
            }}
          >
            Bracket
          </Link>
          <Link
            to={'/leaderboard'}
            style={{
              color: linkColor ? linkColor : 'black',
              fontSize: 18,
              fontWeight: '600',
            }}
          >
            Leaderboard
          </Link>
        </div>
      </div> */}
      <S.LeaderboardContainer bgColor={containerBgColor}>
        <S.Header color={headerColor}>
          <h2>CLASIFICACIÓN</h2>
          {/* <S.StyledLink $isMobile={isMobile} to={`/`}>
            <BackArrow fill={'#fff'} />
          </S.StyledLink> */}
        </S.Header>
        {!isLoading || leaderboard ? (
          <>
            <S.PickersWrapper
              color={pickersWrapperColor}
              bgColor={pickersWrapperBgColor}
            >
              {pickers.map((picker) => (
                <S.Picker
                  color={pickerColor}
                  key={picker.name}
                  isSelected={selectedPicker === picker.name}
                  onClick={() => {
                    setLeaderboardController((prev) => ({
                      ...prev,
                      type: picker.name,
                      period: picker.name,
                    }));
                    setSelectedPicker(picker.name);
                    setPeriodDisplayName(picker.displayName);
                  }}
                >
                  {picker.displayName}
                  {picker.hasPicker && (
                    <S.ArrowWrapper>
                      <ArrowDropDownIcon />
                    </S.ArrowWrapper>
                  )}
                </S.Picker>
              ))}
            </S.PickersWrapper>
            <S.StyledRow bgColor={styledRowBgColor} $isMobile={isMobile}>
              {leaderboard?.selfRanking && (
                <>
                  <S.MyRankTitle
                    color={periodDisplayNameColor ? periodDisplayNameColor : ''}
                  >
                    My Rank
                  </S.MyRankTitle>
                  <S.LeaderboardTable bgColor={leaderBoardTableBgColor}>
                    <Col
                      md={2}
                      style={{
                        textAlign: isMobile ? 'center' : '',
                        color: rankingColor ? rankingColor : '',
                      }}
                    >
                      <S.RankAndMedal>
                        {leaderboard.selfRanking.ranking}
                        {leaderboard.selfRanking.ranking < 3 && (
                          <img
                            src={getMedalImg(leaderboard.selfRanking.ranking)}
                            alt="medal"
                          />
                        )}
                      </S.RankAndMedal>
                    </Col>
                    <Col md={8}>
                      <div
                        style={{ color: nicknameColor ? nicknameColor : '' }}
                      >{`${leaderboard.selfRanking.User?.nickname}`}</div>
                    </Col>
                    <Col
                      md={2}
                      style={{
                        textAlign: 'end',
                        color: pointsColor ? pointsColor : '',
                      }}
                    >
                      {leaderboard.selfRanking.points}
                      {leaderboard.selfRanking.upOrDown === 'up' ? (
                        <RankUp />
                      ) : (
                        <RankDown />
                      )}
                    </Col>
                  </S.LeaderboardTable>
                </>
              )}

              <S.LeaderboardTable bgColor="transparent">
                <S.Members>{`${leaderboard?.usersCount} Usuario`}</S.Members>
                <div
                  style={{
                    color: periodDisplayNameColor ? periodDisplayNameColor : '',
                  }}
                >
                  {periodDisplayName}
                </div>
              </S.LeaderboardTable>

              <S.LeaderboardTable
                bgColor={leaderBoardTableBgColor}
                style={{
                  borderBottomLeftRadius: 'unset',
                  borderBottomRightRadius: 'unset',
                }}
              >
                <Col xs={3} md={'auto'}>
                  <S.GreyTitle defaultColor={greyTitle?.defaultColor}>
                    Ranking
                  </S.GreyTitle>
                </Col>
                <Col xs={7} md={10}>
                  <S.GreyTitle specialColor={greyTitle?.specialColor}>
                    Nombre de usuario
                  </S.GreyTitle>
                </Col>
                <Col xs={'auto'} md={'auto'}>
                  <S.GreyTitle defaultColor={greyTitle?.defaultColor}>
                    Puntos
                  </S.GreyTitle>
                </Col>
              </S.LeaderboardTable>
              <S.StyledHr
                borderWidth={styledHr?.borderWidth}
                borderColor={styledHr?.borderColor}
              />
              <S.LeaderboardTable
                bgColor={leaderBoardTableBgColor}
                style={{
                  borderTopLeftRadius: 'unset',
                  borderTopRightRadius: 'unset',
                  flexDirection: 'column',
                  marginBottom: '1.25rem',
                }}
              >
                {leaderboard?.users?.map((val, index) => (
                  <S.UsersRankRow
                    borderColor={usersRankRowBorderColor}
                    key={val.UserRankings[0].id}
                    $isFirst={index === 0}
                    $isLast={index === leaderboard?.users.length - 1}
                  >
                    <Col
                      xs={2}
                      style={{
                        textAlign: isMobile ? 'center' : '',
                        color: rankingColor ? rankingColor : '',
                      }}
                    >
                      <S.RankAndMedal>
                        {val?.UserRankings[0].ranking}
                        {index < 3 && (
                          <img
                            src={getMedalImg(val?.UserRankings[0].ranking)}
                            alt="medal"
                          />
                        )}
                      </S.RankAndMedal>
                    </Col>
                    <Col xs={7} md={8}>
                      <div
                        style={{ color: nicknameColor ? nicknameColor : '' }}
                      >{`${val?.nickname ?? 'no nickname'}`}</div>
                    </Col>
                    <Col
                      xs={3}
                      md={2}
                      style={{
                        textAlign: 'end',
                        color: pointsColor ? pointsColor : '',
                      }}
                    >
                      {val?.UserRankings[0].points}
                      {val?.UserRankings[0].upOrDown === 'up' ? (
                        <RankUp />
                      ) : (
                        <RankDown />
                      )}
                    </Col>
                  </S.UsersRankRow>
                ))}
              </S.LeaderboardTable>
            </S.StyledRow>
          </>
        ) : (
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AppSpinner />
          </div>
        )}
      </S.LeaderboardContainer>
    </>
  );
};
