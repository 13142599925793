import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const TeamsBoxContainer = styled(Container)`
  position: relative;
  display: flex;
  /* height: 0;*/
  /* margin-top: 30px;  */
  /* transform: translateY(calc(-100% - 1rem)); */
  /* &:nth-child(1) { */
  /* margin-top: 450px; */
  /* } */
`;

export const TeamsBox = styled.div`
  width: 75vw;
  max-width: 500px;
  border: #f1f1f1 solid 2px;
  border-radius: 20px;
  background-color: white;
`;

export const TeamsBoxHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid black;
`;

export const VImage = styled.img`
  height: 26px;
  width: inherit;
  ${({ completed }) =>
    completed && {
      backgroundColor: '#79c000',
      borderRadius: '50%',
      border: '#79c000 2px solid',
    }}
`;

export const TeamName = styled.p`
  margin: unset;
  margin-left: 1em;
  font-weight: 700;
`;