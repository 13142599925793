import React, { useContext, useEffect, useRef, useState } from 'react';
import { TournamentContext } from '../contexts/TournamentContext';

export default function KnockOutOptionSelect({
  possible_teams_arr,
  homeTeam,
  setSelected,
  selected,
  serverSelected,
}) {
  const { enableEditing, postOrPut } = useContext(TournamentContext);

  const selectRef = useRef();

  useEffect(() => {
    if (selectRef.current.value > -1 && !homeTeam) {
      setSelected(null);
      selectRef.current.value = -1;
    } else {
      selectRef.current.value = selected?.id || -1;
    }
  }, [homeTeam, selected]);

  useEffect(() => {
    if (selected && setSelected) {
      if (!possible_teams_arr.find((t) => t.id === selected.id)) {
        selectRef.current.value = -1;
        setSelected(null);
      }
    }
  }, [possible_teams_arr]);

  const handleChange = (e) => {
    const { value } = e.target;
    const temp = possible_teams_arr.find((item) => item.id == value);
    setSelected(temp);
  };

  useEffect(() => {
    if (enableEditing) return;
    setSelected(serverSelected);
  }, [serverSelected]);

  return (
    <div>
      <form style={{ display: 'block' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            overflow: 'visible',
          }}
        >
          <select
            disabled={!enableEditing && postOrPut === 'put'}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onChange={handleChange}
            name="selectedTeam"
            className={`${'form-select-style'} ${selected ? '' : 'unselected'}`}
            style={{
              border: 'none',
            }}
            aria-label="Default select example"
            defaultValue={selected?.id || serverSelected?.id || -1}
            ref={selectRef}
          >
            <option disabled value={-1} hidden>
              Who will play {homeTeam.teamName}?
            </option>
            {possible_teams_arr?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.teamName}
                </option>
              );
            })}
          </select>
        </div>
      </form>
    </div>
  );
}
