import * as S from './TeamStyling.styles';
import CircledVSvg from '../../../GeneralComponents/CircledVSvg';

const TeamStyling = ({
  checkedViState,
  currentHomeTeam,
  selectedResult,
  handleClick,
  homeBackgroundField,
  awayBackgroundField,
  currentAwayTeam,
  TeamFontColor,
  TeamFontSelectedColor,
  selectedVi,
  home,
  away,
  enableEditing,
  isSuccess,
}) => {
  return (
    <div
      style={homeBackgroundField || awayBackgroundField}
      onClick={
        homeBackgroundField
          ? () => handleClick('home_win')
          : () => handleClick('away_win')
      }
      className={
        'd-flex align-items-center' +
        (selectedResult === 'home_win' ? ' checkedTeam' : '')
      }
    >
      <img
        className="small-flag"
        src={currentHomeTeam?.flag_img || currentAwayTeam?.flag_img}
        alt=""
      />

      {/* color of team name */}
      <span
        style={
          (home && selectedResult === 'home_win') ||
          (away && selectedResult === 'away_win')
            ? TeamFontSelectedColor
            : TeamFontColor
        }
        className="ml-2"
      >
        {currentHomeTeam?.displayName || currentAwayTeam?.displayName}
      </span>
      <div className="ml-auto d-flex align-items-center">
        {(home && selectedResult === 'home_win') ||
        (away && selectedResult === 'away_win') ? (
          selectedVi ? (
            <img
              style={{ background: '#ffd500', borderRadius: 50 }}
              src={selectedVi}
              alt="vi"
            />
          ) : (
            <>
              <S.MatchPoints
                className="matchPoints ml-auto mr-2"
                style={TeamFontSelectedColor}
              >
                {isSuccess && '+3 PTS'}
              </S.MatchPoints>
              <CircledVSvg
                // fillColor={'rgba(220, 207, 147, 1)'}
                fillColor={enableEditing ? 'rgba(220, 207, 147, 1)' : '#626262'}
                backgroundColor={{ active: 'black', inActive: 'black' }}
                borderColor={{ active: 'black', inActive: 'black' }}
                isActive
                width={26}
                height={26}
              />
            </>
          )
        ) : (
          enableEditing && (
            <div
              style={{
                width: '19px',
                height: '19px',
                borderRadius: 50,
                border: 'rgba(220, 207, 147, 1) solid 1px',
              }}
            ></div>
          )
        )}
      </div>
    </div>
  );
};

export default TeamStyling;
