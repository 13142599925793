import * as S from './Button.styles';

export const Button = ({
	className,
	title = 'Button',
	icon,
	onClick = null,
	textColor,
	bgColor,
	width,
	height,
	style,
	toggle = false,
	setToggle,
	disabled = false,
	SvgIcon,
	children,
	...otherProps
}) => {
	const onClickHandler = () => {
		setToggle && setToggle((prev) => !prev);
		if (onClick) {
			onClick();
		}
	};

	return (
		<S.Button
			data-html2canvas-ignore
			disabled={disabled}
			width={width}
			height={height}
			bgColor={disabled ? '#e7e7e7' : bgColor}
			textColor={disabled ? '#c2c2c2' : textColor}
			toggle={toggle}
			icon={Boolean(icon)}
			SvgIcon={Boolean(SvgIcon)}
			onClick={onClickHandler}
			className={`${className ? className : ''}`}
			style={style}
			{...otherProps}
		>
			{SvgIcon && (
				// <div>
				<SvgIcon fill={disabled ? '#c2c2c2' : textColor} />
				// </div>
			)}
			{icon && <img src={icon} alt="icon" />}
			<span>{children ? children : title}</span>
		</S.Button>
	);
};

export default Button;
