import React, { useContext, useEffect, useState } from 'react';
import { TournamentContext } from './contexts/TournamentContext';
import TeamsBox from './TeamsBox';
import TournamentHeadline from './headers/TournamentHeadline';
import { useMediaQuery } from '@mui/material';

import './GroupStageContainer.css';
import { language } from '../state/language';
import { Spinner } from 'react-bootstrap';

function GroupStageContainer({ topContainerRef }) {
  const {
    state,
    stageIndex,
    userAddedPredictions,
    colorsPalette,
    isAfterEndOfGroupStage,
    sliderRef,
  } = useContext(TournamentContext);
  //TODO: set to true where receiving the tru data
  const serverGroupPredictions = userAddedPredictions?.groupPredictions;
  const groups = state?.tournament?.groups;
  const isDesktop = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    // console.log({
    //   tour: state?.tournament,
    //   isSubmissionExist: state?.tournament?.isSubmissionExist,
    // });
    if (
      state?.tournament?.groups?.length &&
      !state?.tournament?.isSubmissionExist &&
      isAfterEndOfGroupStage &&
      stageIndex === 0
    ) {
      sliderRef?.current?.slickGoTo(1);
    }
  }, [
    isAfterEndOfGroupStage,
    sliderRef,
    stageIndex,
    state?.tournament,
    state?.tournament?.isSubmissionExist,
  ]);

  useEffect(() => {
    if (topContainerRef?.current) {
      topContainerRef.current.scrollTo({
        behavior: 'smooth',
        block: 'start',
        top: 0,
        left: 0,
      });
    }
  }, [stageIndex, topContainerRef]);

  if (!groups?.length) {
    return (
      <div
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          height: '100vw',
        }}
      >
        <Spinner animation="border" style={{ color: colorsPalette.gold }} />
      </div>
    );
  }

  return (
    <div className="groupState_container" id="topContainer">
      <div className="tournamentHeadline_container">
        <TournamentHeadline
          headline={language?.pages?.group?.mainTitle}
          paragraph={language?.pages?.group?.desc}
          completed={state.tournament.groups.every(
            (g) => g.teams.filter((t) => t.selectedRanking)?.length === 4
          )}
          fromGroups
        />
      </div>
      <div className="teamsBoxs_container">
        {groups?.map((group) => {
          return (
            <TeamsBox
              key={group?.id}
              groupId={group?.id}
              groupLetter={group?.displayName}
              groupKey={group?.key}
              groupSuccesPoints={group?.successPoints}
              teamsInGroup={group?.teams}
              userPredictionGroup={serverGroupPredictions?.find(
                (userAddedGroup) =>
                  userAddedGroup?.TournamentGroupId === group?.id
              )}
              GroupStageContainer
            />
          );
        })}
      </div>
    </div>
  );
}

export default GroupStageContainer;
