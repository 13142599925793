import { Check, PriorityHigh } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { Button } from '@world-cup-client/ui';
import styled from 'styled-components';

export const Paper = styled.div`
  position: relative;
  max-width: 100%;
  height: 85%;
  width: ${({ $isMobile }) =>
    $isMobile ? 'calc(100% - 1.6rem)' : 'calc(50% - 1.6rem)'};
  background-image: ${({ backImg }) => backImg && `url(${backImg})`};
  background-position: ${({ $isMobile }) => ($isMobile ? 'top' : 'center')};
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0rem 0.1875rem 0.3125rem -0.0625rem rgb(0 0 0 / 20%),
    0rem 0.3125rem 0.5rem 0rem rgb(0 0 0 / 14%),
    0rem 0.0625rem 0.875rem 0rem rgb(0 0 0 / 12%);
  border-radius: 0.9375rem;
  padding: 1.4rem;
  padding-bottom: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* gap: 5rem; */
`;

export const ExitBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 1rem;
  width: 1rem;
  background-color: transparent;
  color: #000000;
  border: none;
  color: #fff;
`;

export const Title = styled.h2`
  flex: 1;
  margin-top: 3.5rem;
  text-align: center;
  font-family: 'Prompt';
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: 0.055em;
  line-height: 2.5rem;
  background: linear-gradient(
    224.23deg,
    #dccf93 0%,
    #ecdfa3 18.84%,
    #f4e8b0 40.95%,
    #d1c689 65.43%,
    #b3a76c 85.93%,
    #968b51 100.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  /* gap: 2rem; */
  align-items: center;
  flex-direction: column;
`;

export const Description = styled.p`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 127.7%;
  letter-spacing: -0.025em;
  color: #ffffff;
`;

export const BetBox = styled.div`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  padding: 7px 20px;
  background: rgba(122, 122, 122, 0.3);
  border: 2px solid #ffffff;
  backdrop-filter: blur(2.5px);
  border-radius: 10px;
  color: #75fbda;
  text-align: center;
  margin-bottom: 3rem;
`;

export const SubmitBtn = styled(Button)`
  background: linear-gradient(268.33deg, #2aa788 0%, #75fbda 99.57%);
  border: none;
  color: #000000;
  border-radius: 1.25rem;
  margin: 0 auto;
  display: block;
  width: 15.6rem;
  height: 3rem;
  max-width: calc(100% - 1rem);
  &:disabled {
    background-color: #ffffff4d;
    color: #a89d62;
  }
`;
