import React, { useContext, useMemo } from 'react';
import KnockOutMatchBox from './KnockOutMatchBox';
import { TournamentContext } from '../contexts/TournamentContext';

const QuarterFinalsSection = () => {
  const { stageIndex, stageSize, state } = useContext(TournamentContext);

  const rounds = state?.tournament?.rounds;
  const currentRound = useMemo(
    () => rounds?.find((round) => round?.name === 'Quarter Finals'),
    [rounds]
  );

  let matchesInRound = currentRound?.matches;

  if (matchesInRound) {
    matchesInRound = [...matchesInRound].sort((a, b) => +a.order - +b.order);
  }
  const getGroups = state?.tournament?.groups;

  //TODO: later this will be ordered in the server

  return (
    <div className="teams-box-knockout">
      <div
        className={`quarter-flexbox  ${
          stageIndex < 3 ? 'offsetMatches offsetQuarter' : ''
        }`}
        style={{ height: stageSize }}
      >
        {matchesInRound?.map((match, index) => {
          return (
            <div
              key={match?.id}
              style={{
                marginBottom:
                  stageIndex === 3
                    ? index % 2 === 0
                      ? '0px'
                      : '60px'
                    : '180px',
              }}
            >
              <KnockOutMatchBox
                matchId={match?.id}
                selectedHomeTeamId={match?.selectedHomeTeamId}
                selectedAwayTeamId={match?.selectedAwayTeamId}
                currentRound={currentRound}
                currentMatch={matchesInRound?.find(
                  (matchInRound) => matchInRound?.id === match?.id
                )}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default QuarterFinalsSection;
