import React from 'react';
import QuarterFinalsSection from './QuarterFinalsSection';

import RoundOfSixteenSection from './RoundOfSixteenSection';
import SemiFinalsSection from './SemiFinalsSection';

const TeamsBoxKnockout = ({ RoundOfSixteen, QuarterFinals }) => {
  if (RoundOfSixteen) {
    return <RoundOfSixteenSection />;
  } else if (QuarterFinals) {
    return <QuarterFinalsSection />;
  } else {
    return <SemiFinalsSection />;
  }
};

export default TeamsBoxKnockout;
