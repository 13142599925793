import './App.css';
import './btn.css';
import { Route, Routes } from 'react-router-dom';
import TournamentContainer from './Tournament/TournamentContainer';
import SubmissionSuccess from './Tournament/SubmissionSuccess';
import { TournamentLeaderboard } from '@world-cup-client/ui';

const leaderBoardProps = {
  headerColor: 'white',
  containerBgColor: 'transparent',
  styledRowBgColor: 'transparent',
  pickersWrapperColor: 'rgba(173, 173, 173, 1)',
  pickersWrapperBgColor: 'rgba(52, 52, 52, 0.7)',
  pickerColor: '#75FBDA',
  greyTitle: {
    defaultColor: 'rgba(255, 255, 255, 0.6)',
    specialColor: 'white', // Optional
  },
  styledHr: {
    borderWidth: 2,
    borderColor: 'rgba(75, 75, 75, 1)',
  },
  headerBgColor: 'transparent',
  headerTitleColor: 'white',
  linkColor: 'white',
  periodDisplayNameColor: 'white',
  leaderBoardTableBgColor: 'rgba(52, 52, 52, 0.7)',
  rankingColor: 'rgba(255, 255, 255, 0.6)',
  pointsColor: 'white',
  nicknameColor: 'white',
  usersRankRowBorderColor: '#4B4B4B',
};

const App = () => {
  return (
    <div id="bootstrap-overrides" className="App">
      <Routes>
        <Route
          exact
          path={'/submission-success'}
          element={<SubmissionSuccess />}
        />
        {/* <Route
              exact
              path={'/unauthorized'}
              element={<UnAuthorizedUser />}
            /> */}
        <Route
          exact
          path={'/leaderboard'}
          element={<TournamentLeaderboard {...leaderBoardProps} />}
        />
        <Route exact path={'/'} element={<TournamentContainer />} />
      </Routes>
    </div>
  );
};

export default App;
