import * as S from './Headline.styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import HowToPlay from '../HowToPlay/HowToPlay';
import viLarge from 'shared/assets/Headline/viLarge.png';
import viLargeActive from 'shared/assets/Headline/viLargeActive.png';
import edit from 'shared/assets/Headline/edit.png';
import { toggleEditModeHandler } from 'shared/store/global/globalActions';
import { useQueryClient } from 'react-query';

export const Headline = ({
  headline,
  paragraph,
  editButton = false,
  completed,
  fromGroups,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { editMode, postOrPut } = useSelector(({ global }) => global);
  const tournament = queryClient.getQueryData('tournament')?.tournament;

  const hasTournamentStarted = moment(tournament.startTime).isBefore(
    moment()
  );

  return (
    <S.Headline>
      <div>
        <HowToPlay fromGroups={fromGroups} />
      </div>

      <S.Content>
        <S.VImage
          completed={completed}
          src={completed ? viLargeActive : viLarge}
          alt="v icon"
        />
        <h1 className="EuroHeadline__header-headline">{headline}</h1>
        {editButton && postOrPut === 'put' ? (
          <S.EditBtn
            disabled={hasTournamentStarted}
            onClick={() => dispatch(toggleEditModeHandler())}
            // className={
            //   !editMode && !hasTournamentStarted
            //     ? 'ml-3 btn-edit-enabled d-flex py-2 px-3 mt-3'
            //     : 'ml-3 btn-edit-disabled d-flex py-2 px-3 mt-3'
            // }
            // ! check Edit button disabled mode
          >
            <img className="mr-2" src={edit} alt="edit" />
            Edit
          </S.EditBtn>
        ) : null}
      </S.Content>

      <S.Paragraph>{paragraph}</S.Paragraph>
    </S.Headline>
  );
};

export default Headline;
