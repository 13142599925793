import React, { useContext, useEffect, useState } from 'react';
import viLarge from '../images/viLarge.png';
import viLargeActive from '../images/viLargeActive.png';
import edit from '../images/edit.png';
import { TournamentContext } from '../contexts/TournamentContext';
import HowToPlay from '../HowToPlay';
import moment from 'moment';
import { CircledV, EditIcon } from 'shared/assets/images/icons';
import CircledVSvg from '../../GeneralComponents/CircledVSvg';
import { language } from '../../state/language';

function TournamentHeadline({
  headline,
  paragraph,
  editButton = true,
  completed,
  fromGroups,
}) {
  const {
    postOrPut,
    enableEditing,
    handleEdit,
    toggleEdit,
    state,
    colorsPalette,
    isAfterEndOfGroupStage,
    knockoutStageStartTime,
  } = useContext(TournamentContext);

  const [showEditBtn, setShowEditBtn] = useState(false);

  const hasTournamentStarted = moment(state.tournament.startTime).isBefore(
    moment()
  );

  const hasKnockoutStageStarted = moment(knockoutStageStartTime).isBefore(
    moment()
  );

  useEffect(() => {
    if ((fromGroups && isAfterEndOfGroupStage) || hasKnockoutStageStarted) {
      setShowEditBtn(false);
    } else if (editButton && postOrPut === 'put') {
      setShowEditBtn(true);
    }
  }, [
    editButton,
    fromGroups,
    hasKnockoutStageStarted,
    isAfterEndOfGroupStage,
    postOrPut,
  ]);

  return (
    <div className="EuroHeadline_container" style={{ maxWidth: '500px' }}>
      <HowToPlay fromGroups={fromGroups} />

      <div
        style={{ marginTop: 30 }}
        className="EuroHeadline__headline-and-paragraph"
      >
        <CircledVSvg
          fillColor={colorsPalette.black}
          backgroundColor={{
            active: colorsPalette.gold,
            inActive: colorsPalette.grey,
          }}
          borderColor={{
            active: colorsPalette.gold,
            inActive: colorsPalette.grey,
          }}
          isActive={completed}
          width={26}
          height={26}
        />
        {/* <img
          className={
            'large-vi mt-3 mr-3' + (completed ? ' large-vi-active' : '')
          }
          src={completed ? viLargeActive : viLarge}
          alt="v icon"
        /> */}
        <h1 style={{ margin: 0 }} className="EuroHeadline__header-headline">
          {headline}
        </h1>
        {showEditBtn ? (
          <button
            disabled={
              (!state?.tournament?.onlyKnockout && hasTournamentStarted) ||
              (state?.tournament?.onlyKnockout && hasKnockoutStageStarted)
            }
            onClick={toggleEdit}
            className={
              !enableEditing && !hasTournamentStarted
                ? 'btn-edit-enabled d-flex py-2 px-3'
                : 'btn-edit-disabled d-flex py-2 px-3'
            }
          >
            <EditIcon fill={!enableEditing ? 'grey' : 'white'} />
            {/* <img className="mr-2" src={edit} alt="edit" /> */}
            {language?.editBtn}
          </button>
        ) : null}
      </div>
      {paragraph && <br />}
      <p className="EuroHeadline__paragraph-headline">{paragraph}</p>
    </div>
  );
}

export default TournamentHeadline;
