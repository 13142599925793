import React, { useContext, useMemo } from 'react';
import TeamsBoxKnockout from './TeamsBoxKnockout';
import TournamentHeadline from '../headers/TournamentHeadline';
import { TournamentContext } from '../contexts/TournamentContext';

import './QuarterFinals.css';
import { language } from '../../state/language';

const QuarterFinals = () => {
  const { stageSize, state } = useContext(TournamentContext);

  const completed = useMemo(
    () =>
      state.tournament?.rounds
        ?.find((r) => r.number === 202)
        ?.matches?.every((m) => m.selectedResult),
    [state.tournament?.rounds]
  );

  return (
    <div className="quarterFinal_container">
      <TournamentHeadline
        headline={language?.pages?.quarterFinals?.mainTitle}
        paragraph={language?.pages?.quarterFinals?.desc}
        completed={completed}
      />
      <div
        style={{
          height: stageSize,
          justifyContent: 'space-evenly',
          display: 'flex',
        }}
      >
        <TeamsBoxKnockout QuarterFinals />
      </div>
    </div>
  );
};

export default QuarterFinals;
