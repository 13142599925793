import React, { useContext, useMemo } from 'react';

import TeamsBoxKnockout from './TeamsBoxKnockout';
import TournamentHeadline from '../headers/TournamentHeadline';
import { TournamentContext } from '../contexts/TournamentContext';

import './RoundOfSixteen.css';
import { language } from '../../state/language';

const RoundOfSixteen = () => {
  const { stageSize, state } = useContext(TournamentContext);

  const completed = useMemo(
    () =>
      state.tournament?.rounds
        ?.find((r) => r.number === 201)
        ?.matches?.every((m) => m.selectedResult),
    [state.tournament?.rounds]
  );

  return (
    <div className="roundOfSixteen_container">
      <div style={{ marginBottom: '40px' }}>
        <TournamentHeadline
          headline={language?.pages?.roundOfSixteen?.mainTitle}
          paragraph={language?.pages?.roundOfSixteen?.desc}
          completed={completed}
        />
      </div>
      <div
        style={{
          height: stageSize,
          justifyContent: 'space-evenly',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TeamsBoxKnockout RoundOfSixteen />
      </div>
    </div>
  );
};

export default RoundOfSixteen;
