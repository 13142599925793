import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../Button';

export const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem;
  padding: 0 2rem;
  padding-top: 1.5rem;
  text-align: center;
  color: ${({ color }) => (color ? color : '')};
`;

export const LeaderboardContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#f4f0f0')};
`;

export const StyledRow = styled(Row)`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : 'white')};
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
  flex-direction: column;
  padding: 1rem 10px;
  width: 100%;
  /* ${(props) => props.$isMobile && { padding: '0 10px' }}; */
`;

export const PickersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  max-width: 100%;
  /* width: 100%; */
  overflow: hidden;
  color: ${({ color }) => (color ? color : '#afafaf;')};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : 'white')};
  border-radius: 1.5rem;
  font-weight: 500;
  padding: 0 0.7rem;
`;

export const Picker = styled.button`
  white-space: nowrap;
  color: ${({ isSelected, color }) =>
    isSelected ? (color ? color : '') : 'inherit'};
  background-color: transparent;
  border: none;
  font-weight: inherit;
  font-size: inherit;
`;

export const ArrowWrapper = styled.span`
  width: 0;
  overflow: visible;
`;

export const UpDownImage = styled.div`
  /* width: 1rem;
  margin-left: 0.5rem; */
`;

export const MyRankTitle = styled.div`
  text-align: center;
  padding: 1.2rem 0;
  font-weight: 500;
  font-size: 1rem;
  color: ${({ color }) => color};
`;

export const LeaderboardTable = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#fff')};
  border-radius: 7px;
  font-weight: 500;
  max-width: 100%;
  width: 100%;
  text-align: center;
`;

export const Members = styled.div`
  position: absolute;
  left: 0;
  color: #b7b7b7;
  font-size: 0.9em;
`;

export const GreyTitle = styled.div`
  color: ${({ defaultColor, specialColor }) =>
    specialColor ? specialColor : defaultColor ? defaultColor : '#b7b7b7;'};
`;

export const StyledHr = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  color: #000;
  &:before {
    content: '';
    flex: 1 1;
    border-bottom: ${({ borderWidth }) => (borderWidth ? borderWidth : 1)}px
      solid ${({ borderColor }) => (borderColor ? borderColor : '#dadada')};
    margin: auto;
    margin-right: unset;
  }
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: ${({ borderWidth }) => (borderWidth ? borderWidth : 1)}px
      solid ${({ borderColor }) => (borderColor ? borderColor : '#dadada')};
    margin: auto;
    margin-left: unset;
  }
`;

export const UsersRankRow = styled(Row)`
  margin: unset;
  display: flex;
  width: 100%;
  padding: ${(props) => {
    if (props.$isFirst) {
      return '0 0 1rem 0';
    } else if (props.$isLast) {
      return '1rem 0 0';
    }
    return '1rem 0';
  }};
  border-bottom: ${(props) =>
    !props.$isLast
      ? `1px solid ${props.borderColor ? props.borderColor : '#F5F5F5'}`
      : ''};
`;

export const RankAndMedal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  left: ${({ $isMobile }) => ($isMobile ? '0' : '1rem')};
`;
