import React, { useContext, useEffect, useMemo, useState } from 'react';
import viLarge from './images/viLarge.png';
import viLargeActive from './images/viLargeActive.png';
import GroupStageSingleTeamBox from './GroupStageSingleTeamBox';
import { TournamentContext } from './contexts/TournamentContext';
import { CircledV } from 'shared/assets/images/icons';
import CircledVSvg from '../GeneralComponents/CircledVSvg';
import { colors } from '@mui/material';
import { UserContext } from '../contexts/UserContext';
import { language } from '../state/language';

const containerStyle = {
  position: 'relative',
  transform: 'translateY(calc(-100% - 1rem))',
};

function TeamsBox({
  groupLetter,
  groupKey,
  teamsInGroup,
  groupId,
  userPredictionGroup,
  groupSuccesPoints,
}) {
  const { userAddedPredictions, state, colorsPalette } =
    useContext(TournamentContext);
  const { user } = useContext(UserContext);
  const serverGroupRanking = userPredictionGroup?.GroupPredictionRankings;
  const [isVActive, setIsVActive] = useState(false);

  // const user = Boolean(
  //   sessionStorage.getItem('token') &&
  //     sessionStorage.getItem('token') !== 'null'
  // );
  const teams = state?.tournament?.teams;
  const teamsGroupe = useMemo(
    () => teamsInGroup.map((tg) => teams.find((team) => team?.id === tg.id)),
    [teams, teamsInGroup]
  );
  const rankedTeamsArr = useMemo(
    () => teamsInGroup.filter((t) => t.selectedRanking),
    [teamsInGroup]
  );
  useEffect(() => {
    if (!isVActive && rankedTeamsArr.length === 4) {
      setIsVActive(true);
    } else if (isVActive && rankedTeamsArr.length < 4) {
      setIsVActive(false);
    }
  }, [isVActive, rankedTeamsArr.length]);

  return (
    <div style={{ height: 0, marginTop: '330px' }}>
      <div
        className="container mt-3 group-container d-flex"
        style={containerStyle}
      >
        <div className="teams-box">
          <div className="team-box-header py-2 px-1 d-flex">
            <CircledVSvg
              fillColor={colorsPalette.black}
              backgroundColor={{
                active: colorsPalette.gold,
                inActive: colorsPalette.grey,
              }}
              borderColor={{
                active: colorsPalette.gold,
                inActive: colorsPalette.grey,
              }}
              isActive={isVActive}
              width={26}
              height={26}
            />
            <p className="mb-0 team-box__group-name">{`${language?.pages?.group?.groupPrefixTitle} ${groupKey}`}</p>
            <p className="team-box__details mb-0">
              {/* //TODO: put user logged in condition */}
              {!state?.tournament?.onlyKnockout && user
                ? `${groupSuccesPoints ? groupSuccesPoints : '0'}/12 PTS`
                : null}
            </p>
          </div>
          {teamsGroupe?.map((team) => {
            return (
              <GroupStageSingleTeamBox
                key={team?.id}
                teamId={team?.id}
                teamName={team?.displayName}
                flagImage={team?.flag_img}
                selectedRanking={team?.selectedRanking}
                ranking={team?.ranking}
                groupLetter={groupLetter}
                groupId={groupId}
                userAddedTeamId={serverGroupRanking?.find(
                  (UserAddedTeam) => UserAddedTeam?.teamId === team?.id
                )}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TeamsBox;
