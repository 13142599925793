import * as S from './GroupStageSingleTeamBox.styles';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import successViGroups from 'shared/assets/GroupStage/successViGroups.png';
import failureViGroups from 'shared/assets/GroupStage/failureViGroups.png';
import { useQueryClient } from 'react-query';

const dataSources = {
  user: 'user',
  server: 'server',
};

const selectedBtnColor = {
  backgroundColor: '#4C658A',
  boxShadow: 'inset 0px 3px 4px rgba(0, 0, 0, 0.25)',
  color: '#FFFFFF',
};

const selectedBtnColorDisabled = {
  backgroundColor: '#CFCFCF',
  color: '#FFFFFF',
};

export const GroupStageSingleTeamBox = ({
  teamName,
  flagImage,
  teamId,
  groupLetter,
  ranking,
}) => {
  const queryClient = useQueryClient();
  const { editMode, postOrPut } = useSelector(({ global }) => global);
  const tournament = queryClient.getQueryData('tournament')?.tournament;
  const isAfterEndOfGroupStage = moment().isAfter(
    tournament?.groupStageEndTime
  );

  let fullStringGroupName = groupLetter;
  let parts = fullStringGroupName?.split(' ');
  parts?.shift(); // parts is modified to remove first word
  let onlyLetterOfGroup = parts?.join(' ');
  // const onlyLetterOfGroup = groupLetter?.split(' ').shift().parts?.join(' ');

  let displayedBtn;
  if (postOrPut === 'put') {
    if (editMode) {
      displayedBtn = selectedBtnColor;
    }

    if (!editMode) {
      displayedBtn = selectedBtnColorDisabled;
    }
  } else {
    displayedBtn = selectedBtnColor;
  }

  const groups = tournament?.groups;
  const currentGroup = useMemo(
    () => groups?.find((group) => group?.displayName === groupLetter),
    [groupLetter, groups]
  );

  const teams = currentGroup?.teams;
  const currentTeam = useMemo(
    () => teams?.find((team) => team?.id === teamId),
    [teamId, teams]
  );

  let successOrFailure = undefined;
  if (isAfterEndOfGroupStage) {
    if ((currentTeam?.selectedRanking ?? 4) === ranking) {
      successOrFailure = successViGroups;
    } else {
      successOrFailure = failureViGroups;
    }
  }

  const updateNextMatches = (tournament, matchWithUpdatedSelectedResult) => {
    const nextHomeTeamMatchToUpdate = tournament.rounds
      .map((r) => r.matches)
      .flat()
      .find(
        (m) =>
          m.homeTeamFrom?.previousStage === 'playoff' &&
          +m.homeTeamFrom.previousPosition?.matchApiId ===
            matchWithUpdatedSelectedResult.idInApi
      );
    if (nextHomeTeamMatchToUpdate) {
      switch (matchWithUpdatedSelectedResult.selectedResult) {
        case 'home_win':
          nextHomeTeamMatchToUpdate.selectedHomeTeamId =
            matchWithUpdatedSelectedResult.selectedHomeTeamId;
          updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
          break;
        case 'away_win':
          nextHomeTeamMatchToUpdate.selectedHomeTeamId =
            matchWithUpdatedSelectedResult.selectedAwayTeamId;
          updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
          break;
        case null:
          if (nextHomeTeamMatchToUpdate.selectedResult === 'home_win')
            nextHomeTeamMatchToUpdate.selectedResult = null;
          nextHomeTeamMatchToUpdate.selectedHomeTeamId = null;
          updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
          break;
        default:
          break;
      }
    }
    const nextAwayTeamMatchToUpdate = tournament.rounds
      .map((r) => r.matches)
      .flat()
      .find(
        (m) =>
          m.awayTeamFrom?.previousStage === 'playoff' &&
          +m.awayTeamFrom.previousPosition.matchApiId ===
            matchWithUpdatedSelectedResult.idInApi
      );
    if (nextAwayTeamMatchToUpdate) {
      switch (matchWithUpdatedSelectedResult.selectedResult) {
        case 'home_win':
          nextAwayTeamMatchToUpdate.selectedAwayTeamId =
            matchWithUpdatedSelectedResult.selectedHomeTeamId;
          updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
          break;
        case 'away_win':
          nextAwayTeamMatchToUpdate.selectedAwayTeamId =
            matchWithUpdatedSelectedResult.selectedAwayTeamId;
          updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
          break;
        case null:
          if (nextAwayTeamMatchToUpdate.selectedResult === 'away_win')
            nextAwayTeamMatchToUpdate.selectedResult = null;
          nextAwayTeamMatchToUpdate.selectedAwayTeamId = null;
          updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
          break;
        default:
          break;
      }
    }
  };

  const updateMatchesByGroupRankings = (
    tournament,
    groupKey,
    ranking,
    teamWithNewSelectedRanking /* could be null */
  ) => {
    const nextHomeTeamMatchToUpdate = tournament.rounds
      .map((r) => r.matches)
      .flat()
      .find(
        (m) =>
          m.homeTeamFrom?.previousStage === 'ranking' &&
          m.homeTeamFrom.previousPosition[0].group === groupKey && //TODO: later add functionality for multiple team selection
          +m.homeTeamFrom.previousPosition[0].ranking === ranking
      );
    if (nextHomeTeamMatchToUpdate) {
      nextHomeTeamMatchToUpdate.selectedHomeTeamId =
        teamWithNewSelectedRanking?.id || null;
      updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
    }
    const nextAwayTeamMatchToUpdate = tournament.rounds
      .map((r) => r.matches)
      .flat()
      .find(
        (m) =>
          m.awayTeamFrom?.previousStage === 'ranking' &&
          m.awayTeamFrom.previousPosition[0].group === groupKey &&
          +m.awayTeamFrom.previousPosition[0].ranking === ranking
      );
    if (nextAwayTeamMatchToUpdate) {
      nextAwayTeamMatchToUpdate.selectedAwayTeamId =
        teamWithNewSelectedRanking?.id || null;
      updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
    }
  };

  const selectTeamRanking = (groupKey, ranking, teamId) => {
    console.log('selectTeamRanking');
    queryClient.setQueryData('tournament', (oldTournament) => {
      const updatedTournament = oldTournament?.tournament;
      const groupToUpdate = updatedTournament.groups.find(
        (g) => g.key === groupKey
      );
      if (
        !groupToUpdate ||
        groupToUpdate.teams.find((t) => t?.selectedRanking === ranking)
      ) {
        return oldTournament;
      }
      const teamToUpdate = groupToUpdate.teams.find((t) => t.id === teamId);
      if (teamToUpdate) {
        teamToUpdate.selectedRanking = ranking;
        updateMatchesByGroupRankings(
          updatedTournament,
          groupKey,
          ranking,
          teamToUpdate
        );
      }
      oldTournament.tournament = updatedTournament;
      return oldTournament;
    });
  };

  const deselectTeamRanking = (groupKey, teamId) => {
    console.log('deselectTeamRanking');
    queryClient.setQueryData('tournament', (oldTournament) => {
      const updatedTournament = oldTournament?.tournament;
      const groupToUpdate = updatedTournament.groups.find(
        (g) => g.key === groupKey
      );
      if (!groupToUpdate) return oldTournament;
      const teamToUpdate = groupToUpdate.teams.find((t) => t.id === teamId);
      if (teamToUpdate) {
        const oldRanking = teamToUpdate.selectedRanking;
        teamToUpdate.selectedRanking = null;
        updateMatchesByGroupRankings(
          updatedTournament,
          groupKey,
          oldRanking,
          null
        );
      }
      oldTournament.tournament = updatedTournament;
      return oldTournament;
    });
  };

  const handleClick = (rank, dataSource) => {
    if (editMode) {
      const isRankExist = currentGroup?.teams?.some(
        (team) => team.selectedRanking === rank
      );
      if (currentTeam?.selectedRanking === null && !isRankExist) {
        selectTeamRanking(onlyLetterOfGroup, rank, teamId);
        return;
      }
      if (currentTeam?.selectedRanking !== rank && !isRankExist) {
        currentTeam?.selectedRanking &&
          deselectTeamRanking(onlyLetterOfGroup, teamId);
        setTimeout(() => {
          selectTeamRanking(onlyLetterOfGroup, rank, teamId);
        }, 0);
        return;
      }
      if (currentTeam?.selectedRanking === rank) {
        deselectTeamRanking(onlyLetterOfGroup, teamId);
        return;
      }
    }
  };
  return (
    <S.TeamTableRow>
      <form className="d-flex align-items-center">
        <S.SmallFlag src={flagImage} alt="flag" />
        <S.TeamName>{teamName}</S.TeamName>
        <S.RankingBox>
          {/* user did not add predictions yet */}
          {[...new Array(4)].map((x, i) => {
            return (
              <S.RankingBtn
                key={i}
                style={
                  currentTeam?.selectedRanking === i + 1 ? displayedBtn : null
                }
                onClick={() => handleClick(i + 1, dataSources?.user)}
              >
                {i + 1}
              </S.RankingBtn>
            );
          })}
        </S.RankingBox>
        {successOrFailure && (
          <S.IndicatorImage src={successOrFailure} alt="success or failure" />
        )}
      </form>
    </S.TeamTableRow>
  );
};

export default GroupStageSingleTeamBox;
