import axios from 'axios';
import { createContext, useCallback, useEffect, useState } from 'react';

export const UserContext = createContext(null);

const BASE_URL = process.env.NX_baseUrl;

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  // const jwt = sessionStorage.getItem('token');
  const savedToken = sessionStorage.getItem('token');
  const token =
    savedToken?.length && +savedToken !== 0 && savedToken !== 'null'
      ? savedToken
      : null;

  const getUserProfile = useCallback(async () => {
    try {
      if (!token || token === 'null') return;
      const { data } = await axios.get(`${BASE_URL}/auth/profile`, {
        headers: {
          'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
          'jwt-token': token,
        },
      });
      if (data?.user) {
        setUser(data.user);
      }
    } catch (err) {
      console.log('getUserProfile', err);
      return err;
    }
  }, [token]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  return (
    <UserContext.Provider value={{ user, setUser, refetch: getUserProfile }}>
      {children}
    </UserContext.Provider>
  );
}
