import styled from 'styled-components';

export const TeamTableRow = styled.div`
  padding: 0.5rem;
  border-top: #f1f1f1 solid 2px;
  align-items: center;
`;

export const SmallFlag = styled.img`
  width: 1.5rem;
  height: 1rem;
`;

export const TeamName = styled.span`
  padding-left: 5%;
  white-space: nowrap;
`;

export const RankingBox = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  border: #f1f1f1 solid 2px;
  border-radius: 15px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`;

export const RankingBtn = styled.div`
  &:not(:last-child) {
    border-right: #f1f1f1 solid 2px;
  }

  &:first-child {
    border-radius: 15px 0 0 15px;
  }

  &:last-child {
    border-radius: 0 15px 15px 0;
  }
  padding: .5rem;
`;

export const IndicatorImage = styled.img`
  position: absolute;
  right: 5px;
`;
