import React, { useState, useContext, useRef } from 'react';
import { TournamentContext } from './contexts/TournamentContext';
import GroupStageContainer from './GroupStageContainer';
import { Spinner } from 'react-bootstrap';
import Final from './knockOut/Final';
import SemiFinals from './knockOut/SemiFinals';
import QuarterFinals from './knockOut/QuarterFinals';
import RoundOfSixteen from './knockOut/RoundOfSixteen';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TournamentBody.css';
import Slider from 'react-slick';
import { useMediaQuery } from '@mui/material';

const clickDelay = 620;

const TournamentBody = () => {
  const {
    loading,
    setGroupState,
    setStageSize,
    setStageIndex,
    sliderRef,
    colorsPalette,
  } = useContext(TournamentContext);
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const [disabled, setDisabled] = useState(false);
  const tourBodyRef = useRef(null);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    const click = async () => {
      onClick();
      setDisabled(true);
      await delay(clickDelay);
      setDisabled(false);
    };

    return (
      <button
        disabled={disabled}
        className={className}
        onClick={click}
      ></button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    const click = async () => {
      onClick();
      setDisabled(true);
      await delay(clickDelay);
      setDisabled(false);
    };

    return (
      <button
        disabled={disabled}
        className={className}
        onClick={click}
      ></button>
    );
  }

  const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: clickDelay,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: isDesktop ? false : true,
    centerMode: isDesktop ? true : false,
    draggable: false,
    adaptiveHeight: true,
    nextArrow: isDesktop ? <SampleNextArrow /> : null,
    prevArrow: isDesktop ? <SamplePrevArrow /> : null,

    onSwipe: (dir) => {
      document.activeElement.blur();
    },
    beforeChange: (oldIndex, newIndex) => {
      setStageSize(['80rem', '68rem', '35rem', '30rem', '40rem'][newIndex]);
      setStageIndex(newIndex + 1);
    },
  };

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          height: '100vw',
        }}
      >
        <Spinner animation="border" style={{ color: colorsPalette.gold }} />
      </div>
    );
  } else {
    return (
      <div id="topContainer">
        <div
          className="TournamentBody"
          id="scrollableTournamentBody"
          ref={tourBodyRef}
        >
          <div>
            <Slider {...sliderSettings} ref={sliderRef}>
              <div index={1}>
                <GroupStageContainer topContainerRef={tourBodyRef} />
              </div>
              <div index={2}>
                <RoundOfSixteen />
              </div>
              <div index={3}>
                <QuarterFinals />
              </div>
              <div index={4}>
                <SemiFinals />
              </div>
              <div index={5}>
                <Final />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
};

export default TournamentBody;
