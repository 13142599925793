import { createRef, useRef } from 'react';
import { useQuery } from 'react-query';

export const fetchTournament = async (axiosInstance, tournamentId) => {
  try {
    const { data } = await axiosInstance.get(
      `/api/get-tournament?tournamentId=${tournamentId}`
    );
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const useTournament = (axiosInstance, tournamentId) => {
  const isTriggered = useRef(null);
  return useQuery(
    'tournament',
    () => fetchTournament(axiosInstance, tournamentId),
    {
      onError: (err) => console.error('[useTournament]: fetch failed ', err),
      onSuccess: (data) => {
        if (!isTriggered.current) {
          data.tournament.teams = [
            ...data.tournament.TournamentGroups.map((tg) => tg.teams).flat(),
          ];
          data.tournament.rounds = [
            ...data.tournament.rounds.map((r) => ({
              ...r,
              matches: r.matches.map((m) => ({
                id: m.id,
                idInApi: m.idInApi,
                status_code: m.status_code,
                match_start: m.match_start,
                stage_name: m.stage_name,
                venue: m.venue,
                homeTeamFrom: m.homeTeamFrom,
                awayTeamFrom: m.awayTeamFrom,
                roundId: m.roundId,
                matchScore: m.matchScore,
                order: m.draw_order,

                // this will be updated during the tournament according to result
                awayTeamId: m.awayTeamId,
                homeTeamId: m.homeTeamId,

                selectedAwayTeamId: null,
                selectedHomeTeamId: null,
                // TODO
                // selectedResult:
                //   submission?.tournamentMatchPredictions?.find(
                //     (tmp) => tmp.matchId === m.id
                //   )?.result || null, //"away_win", "home_win" or null if no team selected
              })),
            })),
          ];
          data.tournament.groups = [
            ...data.tournament.TournamentGroups.map((tg) => ({
              ...tg,
              teams: tg.teams.map((t) => ({
                id: t.id,
                // TODO
                // selectedRanking:
                //   submission?.groupPredictions
                //     ?.find((gp) => gp.TournamentGroupId === tg.id)
                //     ?.GroupPredictionRankings?.find(
                //       (gpr) => gpr.teamId === t.id
                //     )?.ranking || null,
                ranking: t.TournamentTeamRanking.ranking, //this is the actual ranking in the tournament
              })),
            })),
          ];
          for (const group of data.tournament.groups) {
            for (const team of group.teams) {
              updateMatchesByGroupRankings(
                data.tournament,
                group.key,
                team.selectedRanking,
                team
              );
            }
          }
          isTriggered.current = true;
          return data;
        }
      },
    }
  );
};

const updateNextMatches = (tournament, matchWithUpdatedSelectedResult) => {
  const nextHomeTeamMatchToUpdate = tournament.rounds
    .map((r) => r.matches)
    .flat()
    .find(
      (m) =>
        m.homeTeamFrom?.previousStage === 'playoff' &&
        +m.homeTeamFrom.previousPosition?.matchApiId ===
          matchWithUpdatedSelectedResult.idInApi
    );
  if (nextHomeTeamMatchToUpdate) {
    switch (matchWithUpdatedSelectedResult.selectedResult) {
      case 'home_win':
        nextHomeTeamMatchToUpdate.selectedHomeTeamId =
          matchWithUpdatedSelectedResult.selectedHomeTeamId;
        updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
        break;
      case 'away_win':
        nextHomeTeamMatchToUpdate.selectedHomeTeamId =
          matchWithUpdatedSelectedResult.selectedAwayTeamId;
        updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
        break;
      case null:
        if (nextHomeTeamMatchToUpdate.selectedResult === 'home_win')
          nextHomeTeamMatchToUpdate.selectedResult = null;
        nextHomeTeamMatchToUpdate.selectedHomeTeamId = null;
        updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
        break;
      default:
        break;
    }
  }
  const nextAwayTeamMatchToUpdate = tournament.rounds
    .map((r) => r.matches)
    .flat()
    .find(
      (m) =>
        m.awayTeamFrom?.previousStage === 'playoff' &&
        +m.awayTeamFrom.previousPosition.matchApiId ===
          matchWithUpdatedSelectedResult.idInApi
    );
  if (nextAwayTeamMatchToUpdate) {
    switch (matchWithUpdatedSelectedResult.selectedResult) {
      case 'home_win':
        nextAwayTeamMatchToUpdate.selectedAwayTeamId =
          matchWithUpdatedSelectedResult.selectedHomeTeamId;
        updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
        break;
      case 'away_win':
        nextAwayTeamMatchToUpdate.selectedAwayTeamId =
          matchWithUpdatedSelectedResult.selectedAwayTeamId;
        updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
        break;
      case null:
        if (nextAwayTeamMatchToUpdate.selectedResult === 'away_win')
          nextAwayTeamMatchToUpdate.selectedResult = null;
        nextAwayTeamMatchToUpdate.selectedAwayTeamId = null;
        updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
        break;
      default:
        break;
    }
  }
};

const updateMatchesByGroupRankings = (
  tournament,
  groupKey,
  ranking,
  teamWithNewSelectedRanking /* could be null */
) => {
  const nextHomeTeamMatchToUpdate = tournament.rounds
    .map((r) => r.matches)
    .flat()
    .find(
      (m) =>
        m.homeTeamFrom?.previousStage === 'ranking' &&
        m.homeTeamFrom.previousPosition[0].group === groupKey && //TODO: later add functionality for multiple team selection
        +m.homeTeamFrom.previousPosition[0].ranking === ranking
    );
  if (nextHomeTeamMatchToUpdate) {
    nextHomeTeamMatchToUpdate.selectedHomeTeamId =
      teamWithNewSelectedRanking?.id || null;
    updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
  }
  const nextAwayTeamMatchToUpdate = tournament.rounds
    .map((r) => r.matches)
    .flat()
    .find(
      (m) =>
        m.awayTeamFrom?.previousStage === 'ranking' &&
        m.awayTeamFrom.previousPosition[0].group === groupKey &&
        +m.awayTeamFrom.previousPosition[0].ranking === ranking
    );
  if (nextAwayTeamMatchToUpdate) {
    nextAwayTeamMatchToUpdate.selectedAwayTeamId =
      teamWithNewSelectedRanking?.id || null;
    updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
  }
};

export default useTournament;
