import './tournament.css';
import TournamentBody from './TournamentBody';
import { useContext } from 'react';
import ClosedGroupStagePlaceholder from '../GeneralComponents/ClosedGroupStagePlaceholder/ClosedGroupStagePlaceholder';
import { TournamentContext } from './contexts/TournamentContext';

const TournamentContainer = () => {
  // const [showClosedPlaceholder, setShowClosedPlaceholder] = useState(false);
  const { showClosedPlaceholder, setShowClosedPlaceholder } =
    useContext(TournamentContext);

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <TournamentBody />
        </div>
      </div>
      <ClosedGroupStagePlaceholder
        isOpen={showClosedPlaceholder}
        setIsOpen={setShowClosedPlaceholder}
      />
    </>
  );
};

export default TournamentContainer;
