import ActionTypes from './../action-types';

export function insertTournament({ tournament, submission }) {
  return {
    type: ActionTypes.INSERT_TOURNAMENT,
    payload: {
      tournament,
      submission,
    },
  };
}
export function selectWinnerForMatch(matchId, result) {
  return {
    type: ActionTypes.SELECT_WINNER_FOR_MATCH,
    payload: {
      result,
      matchId,
    },
  };
}
export function selectTeamRanking(groupKey, ranking, teamId) {
  return {
    type: ActionTypes.SELECT_TEAM_RANKINKG,
    payload: {
      groupKey,
      ranking,
      teamId,
    },
  };
}
export function deselectWinnerForMatch(matchId) {
  return {
    type: ActionTypes.DESELECT_WINNER_FOR_MATCH,
    payload: {
      matchId,
    },
  };
}
export function deselectTeamRanking(groupKey, teamId) {
  return {
    type: ActionTypes.DESELECT_TEAM_RANKING,
    payload: {
      groupKey,
      teamId,
    },
  };
}
export function setPredictedGoals(predictedGoalsNumber) {
  return {
    type: ActionTypes.SET_PREDICTED_GOALS,
    payload: {
      predictedGoalsNumber,
    },
  };
}
export function setPredictedCards(predictedCardsNumber) {
  return {
    type: ActionTypes.SET_PREDICTED_CARDS,
    payload: {
      predictedCardsNumber,
    },
  };
}
export function setPredictedCorners(predictedCornersNumber) {
  return {
    type: ActionTypes.SET_PREDICTED_CORNERS,
    payload: {
      predictedCornersNumber,
    },
  };
}
export function setState(state) {
  return {
    type: ActionTypes.SET_STATE,
    payload: {
      state,
    },
  };
}
export function setTournamentPhase(phase) {
  return {
    type: ActionTypes.SET_TOURNAMENT_PHASE,
    payload: {
      phase,
    },
  };
}
