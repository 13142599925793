import './Final.css';
import { useContext, useEffect, useMemo, useState } from 'react';
import KnockOutMatchBox from './KnockOutMatchBox';
import { TournamentContext } from '../contexts/TournamentContext';
import TournamentHeadline from '../headers/TournamentHeadline';
import vImage from '../images/successSubmitVi.png';
import TournamentEditSubmitModal from './TournamentEditSubmitModal';
import { Checkbox } from '@mui/material';
import { AppSpinner } from '@world-cup-client/ui';
import { SuccessSubmitModal } from '@world-cup-client/ui';
import { language } from '../../state/language';
// import ReactGA from 'react-ga';

const originalFinalFlagImg =
  'https://images.hook-apps.com/final_flags/fifa-2022-Default.svg';

const palmSportsColors = {
  green: '#8bd606',
  yellow: '#ffd500',
  darkGrey: '#181c1f',
  brown: '#674003',
};

const Final = () => {
  const {
    stageIndex,
    handleSubmit,
    state,
    postOrPut,
    enableEditing,
    formFilled,
    showSubmitModal,
    setShowSubmitModal,
    sliderRef,
  } = useContext(TournamentContext);

  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //styles
  const final_height_transition_style = {
    height: stageIndex < 4 ? 0 : '50%',
    transition: 'height 500ms',
    overflow: 'visible',
    position: 'relative',
    // marginTop: '0px',
    minHeight: '350px',
  };

  const [editModelOpened, setEditModelOpened] = useState(false);
  const [agreeChecked, setAgreeChecked] = useState(false);

  const agreeCheckDisabled = !enableEditing || !formFilled;
  const submitDisabled = !enableEditing || !formFilled;

  const totalSuccessPoints = state?.tournament?.totalSuccessPoints;
  const isKnockoutPlayer = state?.tournament?.onlyKnockout;
  const rounds = state?.tournament?.rounds;
  const currentRound = useMemo(
    () => rounds?.find((round) => round?.name === 'Final'),
    [rounds]
  );

  const finalMatch = currentRound?.matches[0];

  const savedToken = sessionStorage.getItem('token');
  const token =
    savedToken?.length && +savedToken !== 0 && savedToken !== 'null'
      ? savedToken
      : null;
  let selectedWinner;

  if (finalMatch?.selectedResult) {
    if (finalMatch.selectedResult === 'home_win') {
      selectedWinner = state?.tournament?.teams?.find(
        (team) => team.id === finalMatch.selectedHomeTeamId
      );
    }
    if (finalMatch.selectedResult === 'away_win') {
      selectedWinner = state?.tournament?.teams?.find(
        (team) => team.id === finalMatch.selectedAwayTeamId
      );
    }
  }

  const completed = state.tournament?.rounds
    ?.find((r) => r.number === 204)
    ?.matches?.every((m) => m.selectedResult);

  useEffect(() => {
    if (postOrPut === 'put' || selectedWinner) {
      setIsLoading(false);
    }
  }, [postOrPut, stageIndex, selectedWinner]);

  return (
    <div className="final_container">
      <div className="TournamentHeadline_final_container">
        <TournamentHeadline
          headline={language?.pages?.final?.mainTitle}
          completed={completed}
        />
      </div>

      <div style={final_height_transition_style}>
        <div className="winning_team_container">
          <h2 className="final_title">
            {language?.pages?.final?.tournamentTitle1} <br />
            {language?.pages?.final?.tournamentTitle2}
          </h2>
          <span className="title_hr" />
          <div className="winning_team_name_style">
            <span className="winning_team_text">
              {selectedWinner?.name || ''}
            </span>
          </div>
          <div>
            {selectedWinner ? (
              <>
                {isLoading && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      minHeight: '140px',
                    }}
                  >
                    <AppSpinner color={'rgb(77, 103, 141)'} />
                  </div>
                )}
                <img
                  style={{ display: !isLoading ? 'block' : 'none' }}
                  onLoad={() => setIsLoading(false)}
                  className="finalFlagImg"
                  src={selectedWinner?.flag_final_img}
                  alt="finalFlagImg"
                />
              </>
            ) : (
              <img
                className={'finalFlagImg'}
                src={originalFinalFlagImg}
                alt="finalFlagImg"
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`final-match-container ${
          stageIndex < 5 ? 'offsetFinalMatch' : ''
        }`}
        style={{ alignItems: 'center' }}
      >
        {+stageIndex !== 4 && <div style={{ height: 0 }} />}
        <KnockOutMatchBox
          key={finalMatch?.id}
          matchId={finalMatch?.id}
          selectedHomeTeamId={finalMatch?.selectedHomeTeamId}
          selectedAwayTeamId={finalMatch?.selectedAwayTeamId}
          currentRound={currentRound}
          currentMatch={finalMatch}
        />

        <div style={{ height: 0 }} />
      </div>
      {enableEditing && (
        <div
          style={{ minWidth: '350px', marginTop: '-50px' }}
          className="submitContainer"
        >
          <div className="terms_continaer">
            <Checkbox
              size="small"
              // color="success"
              checked={checkBoxChecked}
              onChange={() => {
                setCheckBoxChecked((prev) => !prev);
              }}
            />
            <a
              href="#"
              target="_blank"
              style={{ fontSize: '0.7em', marginBottom: '0em', color: 'white' }}
              rel="noreferrer"
            >
              {language?.pages?.final?.termsAndCond}
            </a>
          </div>
          <button
            onClick={async () => {
              // ReactGA.event({
              //   category: `Final submit button`,
              //   action: `send/edit bracket prediction button clicked`,
              // });
              postOrPut === 'post'
                ? await handleSubmit()
                : setEditModelOpened(true);
            }}
            // onClick={async () => {
            // 	postOrPut === 'post' || !token
            // 		? await handleSubmit()
            // 		: setEditModelOpened(true);
            // }}
            className={`button ${
              !checkBoxChecked || submitDisabled ? 'button_disabled' : ''
            }`}
            disabled={!checkBoxChecked || submitDisabled}
          >
            {language?.pages?.final?.submitBtn}
          </button>
        </div>
      )}
      {!enableEditing && (
        <div className="final_score_box_container">
          <div className="final_score_title">
            {language?.pages?.final?.scoreTitle}
          </div>
          <div className="final_score_content">
            {totalSuccessPoints} {language?.pages?.final?.desc1}{' '}
            {isKnockoutPlayer ? 45 : 144} {language?.pages?.final?.desc2}
          </div>
        </div>
      )}
      <TournamentEditSubmitModal
        handleOk={async () => {
          await handleSubmit();
          setEditModelOpened(false);
          sliderRef?.current?.slickGoTo(0);
          return;
        }}
        handleClose={() => {
          setEditModelOpened(false);
        }}
        opened={editModelOpened}
      />
      <SuccessSubmitModal
        colors={palmSportsColors}
        setIsOpen={setShowSubmitModal}
        isOpen={showSubmitModal}
        onSubmit={() => sliderRef?.current?.slickGoTo(0)}
        vImage={vImage}
      />
    </div>
  );
};

export default Final;
