import React, { useContext, useEffect, useState } from 'react';
import checkedVi from '../images/smallVi.png';
import checkedViDisabled from '../images/smallViDisabled.png';
import unCheckedVi from '../images/emptyVi.png';
import successViKnockOut from '../images/successViKnockOut.png';
import failureXKnockOut from '../images/failureViKnockOut.png';
import questionMark from '../images/questionMark.svg';
import { BracketsEmpty } from '../images';
import { BracketsHalf } from '../images';
import { BracketsFull } from '../images';
import detailsImg from '../images/Details.svg';
import KnockOutOptionSelect from './KnockOutOptionSelect';
import isNullOrUndefined from './../../helpers/is-null-or-undefined';
import { TournamentContext } from '../contexts/TournamentContext';
import { ActionCreators } from '../../state/action-creators';
import TeamStyling from './knockOutStyling/TeamStyling';

const weHaveResults = true;

const backgroundCheckedHome = {
  // backgroundColor: 'black',
  background:
    'linear-gradient(88.45deg, #988D53 0.99%, #B9AE7A 23.29%, #E5D99E 55.45%, #F1E4A8 76.72%, #D7CA8D 100.58%)',
  borderRadius: '20px 20px 0 0',
  height: '100%',
};
const backgroundCheckedAway = {
  ...backgroundCheckedHome,
  borderRadius: '0px 0px 20px 20px',
};

const backgroundCheckedHome_gray = {
  ...backgroundCheckedHome,
  background: '#626262',
};

const backgroundCheckedAway_gray = {
  ...backgroundCheckedAway,
  background: '#626262',
};

const noBtnStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'left',
  paddingLeft: '1rem',
  position: 'relative',
  transform: 'translateY(calc(-100% - 1rem))',
};

const btnStyle = {
  ...noBtnStyle,
  transform: 'translateY(calc(-76% - 1rem))',
};

const KnockOutMatchBox = ({
  matchId,
  selectedHomeTeamId,
  selectedAwayTeamId,
  currentMatch,
  currentRound,
}) => {
  const { enableEditing, postOrPut, state, dispatch } =
    useContext(TournamentContext);

  const [successOrFailure, setSuccessOrFailure] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);
  const [whoWon, setWhoWon] = useState(null);

  //get teams
  const tournamentTeams = state?.tournament?.teams;
  const currentHomeTeam = tournamentTeams?.find((team) =>
    selectedHomeTeamId
      ? team?.id === selectedHomeTeamId
      : team?.id === currentMatch?.homeTeamId
  );
  const currentAwayTeam = tournamentTeams?.find((team) =>
    selectedAwayTeamId
      ? team?.id === selectedAwayTeamId
      : team?.id === currentMatch?.awayTeamId
  );

  let homeBackgroundState;
  let awayBackgroundState;
  let checkedViState;
  if (postOrPut === 'put' && !enableEditing) {
    homeBackgroundState = backgroundCheckedHome_gray;
    awayBackgroundState = backgroundCheckedAway_gray;
    checkedViState = false;
  }

  if (postOrPut === 'put' && enableEditing) {
    homeBackgroundState = backgroundCheckedHome;
    awayBackgroundState = backgroundCheckedAway;

    checkedViState = false;
  }
  if (postOrPut === 'post') {
    homeBackgroundState = backgroundCheckedHome;
    awayBackgroundState = backgroundCheckedAway;

    checkedViState = false;
  }

  const level = 0;
  const matchScore = currentMatch?.matchScore;
  const selectedResult = currentMatch?.selectedResult;

  useEffect(() => {
    if (matchScore) {
      if (matchScore?.final_home_score > matchScore?.final_away_score) {
        setWhoWon('home_win');
        return;
      }
      if (matchScore?.final_home_score < matchScore?.final_away_score) {
        setWhoWon('away_win');
        return;
      }
    }
  }, [matchScore]);

  useEffect(() => {
    if (whoWon) {
      if (
        whoWon === selectedResult &&
        ((whoWon === 'away_win' &&
          currentMatch?.awayTeamId &&
          selectedAwayTeamId === currentMatch?.awayTeamId) ||
          (whoWon === 'home_win' &&
            currentMatch?.homeTeamId &&
            selectedHomeTeamId === currentMatch?.homeTeamId))
      ) {
        setSuccessOrFailure(successViKnockOut);
        setIsSuccess(true);
      } else {
        setSuccessOrFailure(failureXKnockOut);
        setIsSuccess(false);
      }
    }
  }, [
    currentMatch?.awayTeamId,
    currentMatch?.homeTeamId,
    selectedAwayTeamId,
    selectedHomeTeamId,
    selectedResult,
    whoWon,
  ]);

  const handleClick = (whoWon) => {
    if (!enableEditing) return;
    if (whoWon !== selectedResult) {
      dispatch(ActionCreators.selectWinnerForMatch(matchId, whoWon));
    } else {
      dispatch(ActionCreators.deselectWinnerForMatch(matchId, whoWon));
    }
  };

  // Inside the following SVG files the stroke color of the empty side is set to white inside the svg code
  // While the full side is controlled by the "current" svg variable inside it's stroke attribute
  let Brackets = {
    SvgComponent: null,
    strokeColor: '',
  };
  if (!selectedHomeTeamId && !selectedAwayTeamId) {
    Brackets.SvgComponent = BracketsEmpty;
    Brackets.strokeColor = '';
  }

  if (selectedHomeTeamId && !selectedAwayTeamId) {
    Brackets.SvgComponent = BracketsHalf;
    Brackets.strokeColor = 'rgba(220, 207, 147, 1)';
  }

  if (selectedAwayTeamId && !selectedHomeTeamId) {
    Brackets.SvgComponent = BracketsHalf;
    Brackets.strokeColor = 'rgba(220, 207, 147, 1)';
  }

  if (selectedHomeTeamId && selectedAwayTeamId) {
    Brackets.SvgComponent = BracketsFull;
    Brackets.strokeColor = 'rgba(220, 207, 147, 1)';
  }

  const bracketsImgElement = (
    <div>
      <Brackets.SvgComponent stroke={Brackets.strokeColor} />
    </div>
  );

  let homeBackgroundField;
  let TeamFontColor = { color: '#ffff' };
  let TeamFontSelectedColor;

  if (selectedResult === 'home_win') {
    homeBackgroundField = {
      ...homeBackgroundState,
      flex: 1,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      borderBottom: 'rgba(220, 207, 147, 1) 1px solid',
      // background:
      //   'linear-gradient(88.45deg, #988D53 0.99%, #B9AE7A 23.29%, #E5D99E 55.45%, #F1E4A8 76.72%, #D7CA8D 100.58%)',
    };
    TeamFontSelectedColor = { color: 'black' };
  } else {
    homeBackgroundField = {
      flex: 1,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      backgroundColor: 'rgba(52, 52, 52, 0.7)',
      borderBottom: 'rgba(220, 207, 147, 1) 1px solid',
      borderRadius: '20px 20px 0 0',
    };
    TeamFontSelectedColor = { color: '#ffd500' };
  }

  let awayBackgroundField;
  if (selectedResult === 'away_win') {
    awayBackgroundField = {
      ...awayBackgroundState,
      flex: 1,
      // background:
      //   'linear-gradient(88.45deg, #988D53 0.99%, #B9AE7A 23.29%, #E5D99E 55.45%, #F1E4A8 76.72%, #D7CA8D 100.58%)',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    };
    TeamFontSelectedColor = { color: 'black' };
  } else {
    awayBackgroundField = {
      flex: 1,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      backgroundColor: 'rgba(52, 52, 52, 0.7)',
      borderRadius: '0px 0px 20px 20px',
    };
    TeamFontSelectedColor = { color: 'black' };
  }

  return (
    <div
      style={{ position: 'relative', height: 0, zIndex: 10 }}
      className="matchBox_container"
    >
      <div style={btnStyle} className="matchBox_inner_container">
        <div className="d-flex">
          {bracketsImgElement}
          <div className="knock-out-match-box">
            {/* color selected home team */}
            {currentHomeTeam && !isNullOrUndefined(currentHomeTeam) ? (
              <TeamStyling
                home
                isSuccess={isSuccess}
                checkedViState={checkedViState}
                currentHomeTeam={currentHomeTeam}
                selectedResult={selectedResult}
                handleClick={handleClick}
                homeBackgroundField={homeBackgroundField}
                TeamFontColor={TeamFontColor}
                TeamFontSelectedColor={TeamFontSelectedColor}
                selectedVi={checkedViState}
                enableEditing={enableEditing}
              />
            ) : (
              <div
                style={{ borderRadius: '20px 20px 0 0', flex: 1 }}
                className="row-empty"
              >
                <img
                  width="25px"
                  height="25px"
                  style={{ margin: 10 }}
                  src={questionMark}
                  alt="vi"
                />
              </div>
            )}

            {/* away team styling */}
            {currentAwayTeam && !isNullOrUndefined(currentAwayTeam) ? (
              <TeamStyling
                away
                isSuccess={isSuccess}
                checkedViState={checkedViState}
                currentAwayTeam={currentAwayTeam}
                selectedResult={selectedResult}
                handleClick={handleClick}
                awayBackgroundField={awayBackgroundField}
                TeamFontSelectedColor={TeamFontSelectedColor}
                TeamFontColor={TeamFontColor}
                selectedVi={checkedViState}
                enableEditing={enableEditing}
              />
            ) : (
              <div
                style={{ borderRadius: '0 0 20px 20px', flex: 1 }}
                className="row-empty"
              >
                <img
                  width="25px"
                  height="25px"
                  style={{ margin: 10 }}
                  src={questionMark}
                  alt="vi"
                />
              </div>
            )}
          </div>
        </div>
        {isSuccess !== null && weHaveResults && (
          <img
            style={{ position: 'absolute', right: '-15px' }}
            src={isSuccess ? successViKnockOut : failureXKnockOut}
            alt="success or failure"
          />
        )}
      </div>
    </div>
  );
};

export default KnockOutMatchBox;
