import * as S from './SuccessSubmitModal.styles';
import { ReactPortal } from '@world-cup-client/ui';
import { useEffect, useRef, useState } from 'react';
import { useWindowResize } from 'shared/hooks';
import axios, { CancelToken } from 'axios';
import { Close as CloseIcon } from '@mui/icons-material';
import { AppSpinner } from '@world-cup-client/ui';
import { Backdrop, Fade, Modal, TextField } from '@mui/material';

const BASE_URL = process.env.NX_baseUrl;

const headerColor = {
	background:
		'linear-gradient(90deg,rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 57.47%)',
	margin: 'auto',
};

export const SuccessSubmitModal = ({
	setIsOpen,
	isOpen,
	onSubmit,
	vImage,
}) => {
	// hooks
	const { isMobile } = useWindowResize();
	const timeoutRef = useRef(null);
	const reqCancelRef = useRef(CancelToken.source());

	// states
	const [nickname, setNickname] = useState(null);
	const [error, setError] = useState(null);
	const [nicknameCheckLoading, setNicknameCheckLoading] = useState(false);
	const [loadingNicknameSave, setLoadingNicknameSave] = useState(false);
	const [isNicknameValid, setIsNicknameValid] = useState(null);

	// query hooks
	// const {
	// 	isLoading: nicknameCheckLoading,
	// 	data,
	// 	error: checkNicknameError,
	// } = useCheckNickname(axiosInstance, nickname);
	// const { mutateAsync: setNicknameRequest, isLoading: loadingNicknameSave } =
	// 	useMutateNickname();

	// constants
	// const nicknameModalTexts = lang?.modals?.nickName;

	// functions
	const cancelLastNicknameCheck = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		if (reqCancelRef.current?.cancel) {
			reqCancelRef.current.cancel();
			reqCancelRef.current = CancelToken.source();
		}
	};

	const submitNicknameHandler = async () => {
		try {
			setNicknameCheckLoading(false);
			setLoadingNicknameSave(true);
			cancelLastNicknameCheck();
			setIsOpen(false);
			if (typeof onSubmit === 'function') {
				onSubmit();
			}
			// let userSelectedNickname;
			// if (nickname?.length) {
			// 	userSelectedNickname = nickname;
			// }
			// await axios.post(`${BASE_URL}/api/set-nickname-to-user`, {
			// 	nickname: userSelectedNickname,
			// });
		} catch (err) {
			console.error(err);
			setError('Error');
		}
	};
	// const submitNicknameHandler = async () => {
	// 	cancelLastNicknameCheck();
	// 	const { data: newNickname } = await setNicknameRequest({
	// 		axiosInstance,
	// 		nickname,
	// 	});
	// 	if (newNickname) {
	// 		dispatch(setUserHandler({ ...user, nickname: newNickname }));
	// 	}
	// 	queryClient.refetchQueries();
	// 	setIsOpen(false);
	// };

	useEffect(() => {
		setNicknameCheckLoading(false);
		if (!nickname || error === 'Invalid nickname') return;
		cancelLastNicknameCheck();
		timeoutRef.current = setTimeout(async () => {
			try {
				setNicknameCheckLoading(true);
				setTimeout(() => {
					setNicknameCheckLoading(false);
					setIsNicknameValid(true);
				}, 1000);
				// const {
				// 	data: { isNicknameUnique },
				// } = await axios.get(
				// 	`${BASE_URL}/api/check-nickname-unique?nickname=${nickname}`,
				// 	{
				// 		cancelToken: reqCancelRef.current.token,
				// 		headers: {
				// 			'app-api-key': process.env.NX_appApiKey,
				// 			// 'jwt-token':
				// 			// 	'eyJhbGciOiJIUzI1NiJ9.eyJtc2FwcHNVc2VySWQiOjIxNDUsImV4cGlyZXMiOjE2NjcxMzkzNDg2MDh9.nG2wxtG2GBGGQktuB-YpGfTvH9TKeRFbcLMxcHiKtsY',
				// 		},
				// 	}
				// );
				// if (isNicknameUnique === false) {
				// 	setError('Nickname already exists');
				// }
			} catch (error) {
				console.error(error);
			}
		}, 500);
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [error, nickname]);

	return (
		<ReactPortal wrapperId="nickname-modal">
			<Modal
				disablePortal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<S.Paper $isMobile={isMobile}>
						<header>
							<div className="text-center p-3">
								<img src={vImage} alt="" />
							</div>
							<h1 className="text-center" style={{ color: '#fff', fontSize: 22 }}>
								Congratulations!
							</h1>
						</header>
						<S.ExitBtn onClick={() => setIsOpen(false)}>
							<CloseIcon />
						</S.ExitBtn>
						<div
							className="container"
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '15px',
								padding: '1.4rem',
								backgroundColor: '#fff',
							}}
						>
							<h2 style={{ fontSize: 18, textAlign: 'center' }}>
								Your predictions was submitted successfully.
							</h2>
							<S.Title>{'Choose Nickname'}</S.Title>
							<S.Description>
								{'Enter your nickname for Leaderboard that other players will see:'}
							</S.Description>
							<TextField
								autoFocus
								disabled={loadingNicknameSave}
								label="Nickname"
								variant="outlined"
								fullWidth
								error={!!error}
								value={nickname || ''}
								onChange={(e) => {
									setNickname(e.target.value);
									setIsNicknameValid(e.target.value === '' ? '' : null);
									!new RegExp(/^[A-Za-z][A-Za-z0-9]*$/).test(e.target.value)
										? setError('Nickname is invalid')
										: setError(null);
								}}
								type="text"
							/>
							{nickname && (
								<S.StatusBoxWrapper>
									{nicknameCheckLoading || isNicknameValid === null ? (
										<AppSpinner color={'#353D49'} />
									) : (
										<S.StatusBoxContent error={!!error}>
											<span>
												{error ? <S.ErrorIcon /> : <S.ApprovedIcon />}
												{error && isNicknameValid !== null
													? error
													: 'Nickname is available'}
											</span>
										</S.StatusBoxContent>
									)}
								</S.StatusBoxWrapper>
							)}
							<S.NoticeBox>
								<span>
									{
										"* If you don't select a nickname you will not be the part of the Leaderboard"
									}
								</span>
							</S.NoticeBox>
							<S.SubmitBtn
								disabled={error || nicknameCheckLoading || isNicknameValid === null}
								onClick={() => {
									if (!nickname || error === 'Invalid nickname') return;
									submitNicknameHandler();
								}}
							>
								OK
							</S.SubmitBtn>
						</div>
					</S.Paper>
				</Fade>
			</Modal>
		</ReactPortal>
	);
};

export default SuccessSubmitModal;
