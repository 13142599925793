import styled from 'styled-components';

export const Headline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  max-width: 500px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const VImage = styled.img`
  height: 26px;
  width: inherit;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  ${({ completed }) =>
    completed && {
      backgroundColor: '#79c000',
      borderRadius: '50%',
      border: '#79c000 2px solid',
    }}
`;

export const EditBtn = styled.button`
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  background: ${({ disabled }) =>
    disabled
      ? '#cfcfcf'
      : 'linear-gradient(100.46deg, #224881 6.42%, #4d678d 100%)'};
  border: 1px solid #ffffff;
  color: white;
  border-radius: 50px;

  &:active {
    background: #1d3b67;
  }
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  font-weight: 300;
  display: inline;
`;
