import * as S from './HowToPlay.styles';
import { useState } from 'react';
import { Button, ClickAwayListener, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

export const HowToPlay = ({ fromGroups }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

  const longText = (
    <p>
      Please predict each group's final standings (1st, 2nd, 3rd, and 4th).
      <br />
      <br />
      Please predict the winner of each knockout match all the way to the final
      and crown the champions.
      <br />
      <br />
      For every correct standing in the groups stage you will score 3 points
      <br />
      <br />
      For every team that advances to the next round of the knockout stages you
      will score 3 points.
      <br />
      <br />
      Earn points, climb the leaderboard to compete to win prizes.
      <br />
    </p>
  );

  return (
    <div style={!fromGroups ? { display: 'none' } : null}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={longText}
          >
            <Button onClose={handleTooltipClose} onClick={handleTooltipOpen}>
              <div className="d-flex">
                <span style={{ fontWeight: 'bolder' }}>
                  HOW TO PLAY?
                  <HelpOutline
                    style={{
                      color: '#000',
                      width: 20,
                    }}
                    alt="tooltip"
                  />
                </span>
              </div>
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default HowToPlay;
