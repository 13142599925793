import * as S from './TeamsBox.styles';
import viLarge from 'shared/assets/Headline/viLarge.png';
import viLargeActive from 'shared/assets/Headline/viLargeActive.png';
import GroupStageSingleTeamBox from '../GroupStageSingleTeamBox/GroupStageSingleTeamBox';
import { useQueryClient } from 'react-query';

export const TeamsBox = ({ groupLetter, teamsInGroup, groupId }) => {
  const queryClient = useQueryClient();
  const tournament = queryClient.getQueryData('tournament')?.tournament;

  const teams = tournament?.teams;
  const teamsGroupe = teamsInGroup.map((tg) =>
    teams.find((team) => team?.id === tg.id)
  );

  const rankedTeamsArr = teamsInGroup.filter((t) => t.selectedRanking);

  return (
    <S.TeamsBoxContainer>
      <S.TeamsBox>
        <S.TeamsBoxHeader>
          <S.VImage
            completed={rankedTeamsArr.length === 4}
            src={rankedTeamsArr.length === 4 ? viLargeActive : viLarge}
            alt="vi"
          />
          <S.TeamName>{groupLetter}</S.TeamName>
        </S.TeamsBoxHeader>
        {teamsGroupe?.map((team) => {
          return (
            <GroupStageSingleTeamBox
              key={team?.id}
              teamId={team?.id}
              teamName={team?.name}
              flagImage={team?.flag_img}
              selectedRanking={team?.selectedRanking}
              ranking={team?.ranking}
              groupLetter={groupLetter}
              groupId={groupId}
            />
          );
        })}
      </S.TeamsBox>
    </S.TeamsBoxContainer>
  );
};

export default TeamsBox;
