import React, { useContext, useMemo } from 'react';
import { TournamentContext } from '../contexts/TournamentContext';
import KnockOutMatchBox from './KnockOutMatchBox';

const SemiFinalsSection = () => {
  const { stageIndex, stageSize, state } = useContext(TournamentContext);

  const rounds = state?.tournament?.rounds;
  const currentRound = useMemo(
    () => rounds?.find((round) => round?.name === 'Semi Finals'),
    [rounds]
  );

  let matchesInRound = currentRound?.matches;

  if (matchesInRound) {
    matchesInRound = [...matchesInRound].sort((a, b) => +a.order - +b.order);
  }
  //TODO: later this will be ordered in the server

  return (
    <div className="teams-box-knockout">
      <div
        className={`semi-flex-container ${
          stageIndex < 4 ? 'offsetMatches offsetSemiFinal' : ''
        }`}
        style={{ height: stageSize }}
      >
        {matchesInRound?.map((match, index) => {
          return (
            <div
              key={match?.id}
              style={{
                zIndex: 10,
                marginBottom:
                  stageIndex === 5
                    ? index % 2 === 0
                      ? '0px'
                      : '80px'
                    : '160px',
              }}
            >
              <KnockOutMatchBox
                matchId={match?.id}
                selectedHomeTeamId={match?.selectedHomeTeamId}
                selectedAwayTeamId={match?.selectedAwayTeamId}
                currentRound={currentRound}
                currentMatch={matchesInRound?.find(
                  (matchInRound) => matchInRound?.id === match?.id
                )}
              />
            </div>
          );
        })}
      </div>

      <div className="next-stage"></div>
    </div>
  );
};
export default SemiFinalsSection;
