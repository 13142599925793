import React, { useContext, useEffect, useMemo, useState } from 'react';
import KnockOutMatchBox from './KnockOutMatchBox';
import { TournamentContext } from '../contexts/TournamentContext';

import { useMediaQuery } from '@mui/material';

const RoundOfSixteenSection = ({ setGroupsSixteen }) => {
  const { stageIndex, stageSize, state } = useContext(TournamentContext);
  const isDesktop = useMediaQuery('(min-width:768px)');

  const rounds = state?.tournament?.rounds;
  const currentRound = useMemo(
    () => rounds?.find((round) => round?.name === '1/8'),
    [rounds]
  );

  const [matchesInRound, setMatchesInRound] = useState([]);

  useEffect(() => {
    if (currentRound?.matches) {
      const sortedMatchesInRound = [...currentRound.matches].sort((a, b) =>
        stageIndex === 2
          ? +a.order - +b.order
          : a.homeTeamFrom.previousPosition[0].group.localeCompare(
              b.homeTeamFrom.previousPosition[0].group,
              'en',
              { sensitivity: 'base' }
            )
      );
      setMatchesInRound(sortedMatchesInRound);
    }
  }, [currentRound?.matches, stageIndex]);

  return (
    <div className="teams-box-knockout">
      <div
        className={`matches16-flex-container ${
          stageIndex < 2 ? 'offSetSixteenMatches' : ''
        }`}
        style={{ height: stageSize }}
      >
        {matchesInRound?.map((match, index) => {
          return (
            <div
              key={match?.id}
              style={{
                marginBottom:
                  stageIndex === 2
                    ? index % 2 === 0
                      ? '0px'
                      : '60px'
                    : '211px',
              }}
            >
              <KnockOutMatchBox
                matchId={match?.id}
                selectedHomeTeamId={match?.selectedHomeTeamId}
                selectedAwayTeamId={match?.selectedAwayTeamId}
                currentRound={currentRound}
                currentMatch={match}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default RoundOfSixteenSection;
