export const TOGGLE_ASKED_FOR_NICKNAME = 'TOGGLE_ASKED_FOR_NICKNAME';
export const SET_AXIOS_INSTANCE = 'SET_AXIOS_INSTANCE';
export const TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const TOGGLE_HAS_ROUND_STARTED = 'TOGGLE_HAS_ROUND_STARTED';
export const SET_USER = 'SET_USER';
export const SET_DEVICE = 'SET_DEVICE';
export const SET_STAGE = 'SET_STAGE';
export const UPDATE_STAGE = 'UPDATE_STAGE';
export const SET_POST_OR_PUT = 'SET_POST_OR_PUT';

export const setDevice = (device = null) => {
  return { type: SET_DEVICE, payload: device };
};

export const toggleSideNavHandler = (toggle = null) => {
  return { type: TOGGLE_SIDE_NAV, payload: toggle };
};

export const toggleAskedForNicknameHandler = (askedForNickname = null) => {
  return { type: TOGGLE_ASKED_FOR_NICKNAME, payload: askedForNickname };
};

export const toggleEditModeHandler = (editMode = null) => {
  return { type: TOGGLE_EDIT_MODE, payload: editMode };
};

export const toggleHasRoundStarterHandler = (hasRoundStarted = null) => {
  return { type: TOGGLE_HAS_ROUND_STARTED, payload: hasRoundStarted };
};

export const setUserHandler = (user) => {
  return { type: SET_USER, payload: user };
};

export const setAxiosInstance = (axiosInstance = null) => {
  return { type: SET_AXIOS_INSTANCE, payload: axiosInstance };
};

export const setStageHandler = (prop = null, value = null) => {
  return { type: SET_STAGE, payload: { prop, value } };
};

export const updateStageHandler = (value = {}) => {
  return { type: UPDATE_STAGE, payload: value };
};

export const setPostOrPutHandler = (value = null) => {
  if(value) {
    return { type: SET_POST_OR_PUT, payload: value };
  }
}