import { CircledV } from 'shared/assets/images/icons';

const CircledVSvg = ({
  isActive,
  backgroundColor,
  borderColor,
  fillColor,
  width,
  height,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: isActive
          ? backgroundColor.active
          : backgroundColor.inActive,
        border: `${
          isActive ? borderColor.active : borderColor.inActive
        } solid 1px`,
        borderRadius: '120%',
      }}
    >
      <CircledV width={width} height={height} fill={fillColor} />
    </div>
  );
};

export default CircledVSvg;
