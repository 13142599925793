import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import '../src/styles.css';

import ScrollToTop from './app/GeneralComponents/ScrollToTop';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery';
import 'popper.js/dist/popper';
import 'bootstrap/dist/js/bootstrap';
import axios from 'axios';
import { UserProvider } from './app/contexts/UserContext';
import * as ReactDOM from 'react-dom/client';
import App from './app/App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TournamentProvider } from './app/Tournament/contexts/TournamentContext';

const theme = createTheme();

// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-204804134-6');

// ReactGA.ga('create', 'UA-204804134-6', 'auto', {
//   cookieFlags: 'SameSite=None; Secure',
// });

const jwt = localStorage.getItem('jwt-token');
jwt && (axios.defaults.headers.common['jwt-token'] = jwt);

const urlParams = new URLSearchParams(window.location.search);
sessionStorage.setItem('token', urlParams.get('token'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <TournamentProvider>
              <App />
            </TournamentProvider>
          </ThemeProvider>
        </UserProvider>
      </ScrollToTop>
    </Router>
  </React.StrictMode>
);
