import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { TournamentContext } from './contexts/TournamentContext';
import { selectTeamRanking } from '../state/action-creators/action-creators';
import { ActionCreators } from '../state/action-creators';
import successViGroups from './images/successViGroups.png';
import failureViGroups from './images/failureViGroups.png';
import { UserContext } from '../contexts/UserContext';

const dataSources = {
  user: 'user',
  server: 'server',
};

const selectedBtnColor = {
  background:
    'linear-gradient(224.23deg, #DCCF93 0%, #ECDFA3 18.84%, #F4E8B0 40.95%, #D1C689 65.43%, #B3A76C 85.93%, #968B51 100.88%)',
  boxShadow: 'inset 0px 3px 4px rgba(0, 0, 0, 0.25)',
  color: 'var(--darkGrey)',
};
const unSelectedBtnColor = {
  backgroundColor: '#626262',
  boxShadow: 'inset 0px 3px 4px rgba(0, 0, 0, 0.25)',
  color: '#FFFFFF',
};

const selectedBtnColorDisabled = {
  backgroundColor: '#626262',
  color: '#FFFFFF',
};

const GroupStageSingleTeamBox = ({
  teamName,
  flagImage,
  teamId,
  groupLetter,
  // selectedRanking,
  ranking,
}) => {
  const { postOrPut, state, enableEditing, isAfterEndOfGroupStage, dispatch } =
    useContext(TournamentContext);
  const { user } = useContext(UserContext);
  const savedToken = sessionStorage.getItem('token');
  const token =
    savedToken?.length && +savedToken !== 0 && savedToken !== 'null'
      ? savedToken
      : null;

  const [hasSelected, setHasSelected] = useState(false);

  let fullStringGroupName = groupLetter;
  let parts = fullStringGroupName?.split(' ');
  parts?.shift(); // parts is modified to remove first word
  let onlyLetterOfGroup = parts?.join(' ');
  let predictionIsCorrect;

  let displayedBtn;
  if (postOrPut === 'put') {
    if (enableEditing) {
      displayedBtn = selectedBtnColor;
    }

    if (!enableEditing) {
      displayedBtn = selectedBtnColorDisabled;
    }
  } else {
    displayedBtn = selectedBtnColor;
  }
  // if (postOrPut === 'put' && !enableEditing) {
  //   displayedBtn = unSelectedBtnColor;
  // }

  // if (postOrPut === 'put' && enableEditing) {
  //   displayedBtn = selectedBtnColor;
  // }
  // if (postOrPut === 'post') {
  //   displayedBtn = selectedBtnColor;
  // }
  // background-color: black;
  // color: white;
  // if (postOrPut === 'put') {
  //   if (enableEditing) {
  //     displayedBtn = selectedBtnColor;
  //   }

  //   if (!enableEditing) {
  //     displayedBtn = selectedBtnColorDisabled;
  //   }
  // } else {
  //   displayedBtn = selectedBtnColor;
  // }

  const groups = state?.tournament?.groups;
  const currentGroup = useMemo(
    () => groups?.find((group) => group?.displayName === groupLetter),
    [groupLetter, groups]
  );

  const teams = currentGroup?.teams;
  const currentTeam = useMemo(
    () => teams?.find((team) => team?.id === teamId),
    [teamId, teams]
  );

  let successOrFailure = undefined;
  if (isAfterEndOfGroupStage && state?.tournament?.isSubmissionExist) {
    if ((currentTeam?.selectedRanking ?? 4) === ranking) {
      successOrFailure = successViGroups;
      // predictionIsCorrect = true;
    } else {
      successOrFailure = failureViGroups;
    }
  }

  // const currentTeam = currentGroup?.find((group) => group?.teams)
  const handleClick = (rank, dataSource) => {
    if (enableEditing && !isAfterEndOfGroupStage) {
      if (currentTeam?.selectedRanking === null) {
        dispatch(
          ActionCreators.selectTeamRanking(onlyLetterOfGroup, rank, teamId)
        );
        setHasSelected(true);
        return;
      }
      if (currentTeam?.selectedRanking !== rank) {
        dispatch(ActionCreators.deselectTeamRanking(onlyLetterOfGroup, teamId));
        dispatch(
          ActionCreators.selectTeamRanking(onlyLetterOfGroup, rank, teamId)
        );
        setHasSelected(false);
      }
      if (currentTeam?.selectedRanking === rank) {
        dispatch(ActionCreators.deselectTeamRanking(onlyLetterOfGroup, teamId));
        setHasSelected(false);
      }
    }
  };

  useLayoutEffect(() => {
    if (state?.tournament) {
      const { onlyKnockout } = state.tournament;
      if ((!user || !token || onlyKnockout) && isAfterEndOfGroupStage) {
        dispatch(
          ActionCreators.selectTeamRanking(
            onlyLetterOfGroup,
            currentTeam?.ranking,
            teamId
          )
        );
      }
    }
  }, [
    currentTeam?.ranking,
    dispatch,
    isAfterEndOfGroupStage,
    onlyLetterOfGroup,
    state.tournament,
    teamId,
    token,
    user,
  ]);

  return (
    <div
      className="team-table-row py-2 px-1 align-items-center overflow-hidden"
      style={{
        minWidth: 'fit-content',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className="d-flex align-items-center">
          <img className="small-flag" src={flagImage} alt="flag" />
          <span className={`name-team`}>{teamName}</span>
        </div>
        {/* <span className="matchPoints ml-auto mr-5">
          {predictionIsCorrect && '+3 PTS'}
        </span> */}

        <div
          className="d-flex ranking-box"
          style={{
            marginRight:
              !state?.tournament?.onlyKnockout && successOrFailure
                ? '10px'
                : 'unset',
          }}
        >
          {/* user did not add predictions yet */}
          {[...new Array(4)].map((x, i) => (
            <div
              key={i}
              style={
                currentTeam?.selectedRanking === i + 1 ||
                (!currentTeam?.selectedRanking &&
                  isAfterEndOfGroupStage &&
                  currentTeam?.ranking === i + 1)
                  ? displayedBtn
                  : null
              }
              className={`ranking-btn ranking-btn-${i + 1} px-2 py-1`}
              onClick={() => handleClick(i + 1, dataSources?.user)}
            >
              {i + 1}
            </div>
          ))}

          {!state?.tournament?.onlyKnockout && successOrFailure && (
            <img
              style={{ position: 'absolute', right: '5px' }}
              src={successOrFailure}
              alt="success or failure"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupStageSingleTeamBox;
