import styled from 'styled-components';

export const MatchPoints = styled.span`
  margin-left: auto;
  margin-right: 10px;
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;
