export const language = {
  pages: {
    group: {
      mainTitle: 'FASE DE GRUPOS',
      desc: `¡Adivina ya! ¿Qué equipos ocuparán el 1º, 2º, 3º y 4º
      lugar al finalizar la Fase de Grupos?`,
      groupPrefixTitle: 'Grupo',
      howToPlay: {
        title: '¿cómo jugar?',
      },
    },
    roundOfSixteen: {
      mainTitle: 'OCTAVOS DE FINAL',
      desc: '',
    },
    quarterFinals: {
      mainTitle: 'CUARTOS DE FINAL',
      desc: ``,
    },
    semiFinals: {
      mainTitle: 'SEMIFINALES',
      desc: '',
    },
    final: {
      mainTitle: 'FINAL',
      tournamentTitle1: 'WORLD CUP ',
      tournamentTitle2: 'QATAR 2022',
      termsAndCond: 'Términos y Condiciones',
      submitBtn: 'Enviar',
      scoreTitle: 'Your Score',
      desc1: 'salir',
      desc2: 'Puntos',
    },
    success: {
      mainTitle1: '¡Porra Completada!',
      mainTitle2: '',
      nextBtn: 'Finalizar',
      nickname: {
        mainTitle: 'Selecciona un nombre de usuario',
        desc: 'Nombre de usuario para la clasificación, este nombre lo verán el resto de usuarios:',
        placeholder: 'Nombre de usuario',
        validNickname: 'Nombre de usuario no disponible',
        invalidNickname: 'Nombre de usuario no disponible',
        noticeMsg:
          '* Necesitas un nombre de usuario para poder formar parte de la clasificación',
      },
      // leaderboard: {
      //   mainTitle: 'CLASIFICACIÓN',

      // },
    },
  },
  modals: {
    disclaimer: {
      title: 'NOTIFICACIÓN',
      desc: 'Según la normativa, la obtención de premios será exclusiva para usuarios registrados en VERSUS desde hace más de 30 días, con la cuenta verificada, sin retiradas pendientes, aumento de límites en un periodo menor a 30 días ni usuarios clasificados como “jugador intensivo”. El resto de los participantes lo harán únicamente de forma recreacional.',
      acceptBtn: 'Aceptar',
    },
    edit: {
      desc: '¿Estás seguro de los cambios?',
      yesBtn: 'SÍ',
      noBtn: 'NO',
    },
    outright: {
      mainTitle1: 'Hacer una apuesta',
      mainTitle2: 'HAS GANADO',
      desc: 'ganará el torneo',
      submitBtn: 'Apuesta Ahora!',
    },
  },
  components: {
    howToPlay: {
      firstStep:
        'Por favor, elige la posición de los equipos en cada grupo (1º,2º,3º y 4º).',
      secondStep:
        'Por favor, elige el ganador de la eliminatoria durante la fase final y el ganador del Mundial.',
      thirdStep:
        'Por cada posición acertada en la Fase de Grupos conseguirás 3 puntos.',
      fourthStep:
        'Por cada equipo que pase a la siguiente eliminatoria conseguirás 3 puntos.',
      fifthStep:
        'Consigue puntos, asciende en la clasificación y gana premios.',
    },
  },
  editBtn: 'Editar',
};

// const EnglishLanguage = {
//   pages: {
//     group: {
//       mainTitle: 'GROUP STAGE',
//       desc: 'Please select which teams will end up 1st, 2nd, 3rd, and 4th',
//       groupPrefixTitle: 'Group',
//       howToPlay: {
//         title: 'HOW TO PLAY',
//       },
//     },
//     roundOfSixteen: {
//       mainTitle: 'ROUND OF SIXTEEN',
//       desc: 'Please select the winner of each match',
//     },
//     quarterFinals: {
//       mainTitle: 'QUARTER FINALS',
//       desc: 'Please select the winner of each match',
//     },
//     semiFinals: {
//       mainTitle: 'SEMI FINALS',
//       desc: 'Please select the winner of each match',
//     },
//     final: {
//       mainTitle: 'FINAL',
//       tournamentTitle1: 'WORLD CUP ',
//       tournamentTitle2: 'QATAR 2022',
//       termsAndCond: 'Terms and conditions',
//       submitBtn: 'Submit',
//       scoreTitle: 'Your Score',
//       desc1: 'out of',
//       desc2: 'Points',
//     },
//     success: {
//       mainTitle1: 'Predictions submited',
//       mainTitle2: 'successfully!',
//       nextBtn: 'Next',
//       nickname: {
//         mainTitle: 'Choose Nickname',
//         desc: 'Enter your nickname for Leaderboard that other players will see:',
//         placeholder: 'Nickname',
//         validNickname: 'This nickname is unique',
//         invalidNickname: 'This nickname is already taken',
//         noticeMsg:
//           "* If you don't select a nickname you will not be the part of the Leaderboard",
//       },
//     },
//   },
//   modals: {
//     disclaimer: {
//       title: 'NOTIFICACIÓN',
//       desc: 'Según la normativa, la obtención de premios será exclusiva para usuarios registrados en VERSUS desde hace más de 30 días, con la cuenta verificada, sin retiradas pendientes, aumento de límites en un periodo menor a 30 días ni usuarios clasificados como “jugador intensivo”. El resto de los participantes lo harán únicamente de forma recreacional.',
//       acceptBtn: 'Aceptar',
//     },
//     edit: {
//       desc: 'Are you sure you want to make changes?',
//       yesBtn: 'Yes',
//       noBtn: 'No',
//     },
//     outright: {
//       mainTitle1: 'PLACE A BET ON',
//       mainTitle2: 'YOUR WINNER',
//       desc: 'to win the tournament',
//       submitBtn: 'Bet Now!',
//     },
//   },
//   components: {
//     howToPlay: {
//       firstStep:
//         "Please predict each group's final standings (1st, 2nd, 3rd, and 4th).",
//       secondStep:
//         'Please predict the winner of each knockout match all the way to the final and crown the champions.',
//       thirdStep:
//         'For every correct standing in the groups stage you will score 3 points',
//       fourthStep:
//         'For every team that advances to the next round of the knockout stages you will score 3 points.',
//       fifthStep: 'Earn points, climb the leaderboard to compete to win prizes.',
//     },
//   },
//   editBtn: 'Edit',
// };
