import styled from 'styled-components';
import BgImage from '../../images/general/ClosedGroupStagePlaceholderBg.png';
import { Button } from '@world-cup-client/ui';

export const PlaceholderWrapper = styled.div`
  position: relative;
  max-width: 100%;
  height: 85%;
  width: ${({ $isMobile }) => ($isMobile ? '85%' : '50%')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0rem 0.1875rem 0.3125rem -0.0625rem rgb(0 0 0 / 20%),
    0rem 0.3125rem 0.5rem 0rem rgb(0 0 0 / 14%),
    0rem 0.0625rem 0.875rem 0rem rgb(0 0 0 / 12%); */
  border-radius: 2rem;
  /* padding: 1.4rem;
  padding-bottom: 3rem; */

  background-image: url(${BgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 100vw; */
  /* height: 100vh; */
  background-color: #000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 800;
  font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '23px')};
  line-height: 32px;
  letter-spacing: 0.065em;
  color: #ffffff;
  text-align: center;
`;

export const TR = styled.tr`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: #ffffff;
`;

export const Content = styled.div`
  /* flex: 3; */
  display: flex;
  /* justify-content: space-between; */
  gap: 2rem;
  align-items: center;
  text-align: start;
  flex-direction: column;
`;

export const vl = styled.div`
  /* width: 85px; */
  height: 100px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  /* transform: rotate(90deg); */
`;

export const Description = styled.p`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #ffffff;
`;

export const SubmitBtn = styled(Button)`
  /* background: linear-gradient(268.33deg, #2aa788 0%, #75fbda 99.57%); */
  border: none;
  color: #000000;
  /* border-radius: 1.25rem; */
  margin: 0 auto;
  display: block;
  width: 15.6rem;
  height: 3rem;
  max-width: calc(100% - 1rem);
  background: #000000;
  border-radius: 20px;
`;

export const SubmitBtnText = styled.span`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;

  background: linear-gradient(
    224.23deg,
    #dccf93 0%,
    #ecdfa3 18.84%,
    #f4e8b0 40.95%,
    #d1c689 65.43%,
    #b3a76c 85.93%,
    #968b51 100.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;
