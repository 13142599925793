import { useEffect, useState } from 'react';

export const useWindowResize = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const [isMobile, setIsMobile] = useState(width <= 767);
	const [isXsmall, setIsXsmall] = useState(width <= 599);
	const [isSmall, setIsSmall] = useState(width >= 600);
	const [isTablet, setisTablet] = useState(width >= 768);
	const [isLarge, setisLarge] = useState(width >= 1024);
	const [isXlarge, setisXlarge] = useState(width >= 1200);
	const listener = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};

	useEffect(() => {
		window.addEventListener('resize', listener);
		return () => {
			window.removeEventListener('resize', listener);
		};
	}, []);

	useEffect(() => {
		setIsMobile(width <= 767);
		setIsXsmall(width <= 599);
		setIsSmall(width >= 600);
		setisTablet(width >= 768);
		setisLarge(width >= 1024);
		setisXlarge(width >= 1200);
	}, [width]);

	return {
		width,
		height,
		isMobile,
		isXsmall,
		isSmall,
		isTablet,
		isLarge,
		isXlarge,
	};
};

export default useWindowResize;
