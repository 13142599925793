import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-focus-ring-color: none;
  }
  @font-face {
  font-family: 'Myriad';
  src: url('shared/assets/fonts/Myriad-Pro-Black-Condensed.otf');
  }
  html {
  height: 100%;
  background-color: #f4f0f0;
}
.slick-slider,
.slick-track {
  /* display: flex !important; */
}

.slick-slide {
  height: auto;
}
.slick-prev {
  position: absolute;
  top: 5px;
  left: 6% !important;
  z-index: 1;
}
.slick-next {
  position: absolute;
  top: 5px;
  right: 6% !important;
  z-index: 1;
}

.slick-prev,
.slick-next {
	color: rgb(16, 43, 83) !important;
	font-size: 44px !important;
}

.slick-disabled {
  display: none !important;
  cursor: none;
}

@media all and (max-width: 768px) {
  .slick-prev,
.slick-next {
  display: none !important;
}
.slick-prev:before,
.slick-next:before {
  display: none !important;
}


}

.slick-slider {
  overflow: hidden;
}
  `;
export default GlobalStyles;

/* Smooth Scroll  */
/* [data-scrollbar] {
    height: 100vh;
    overflow: hidden;
    background-color: var(--gray-1);
    .scroll-content {
      background-color: var(--dark-bg);
    }
    .scrollbar-track.scrollbar-track-y {
      background: var(--deep-dark);
      .scrollbar-thumb-y {
        background: var(--gray-1);
      }
    }
  } */
