const ActionTypes = {
  INSERT_TOURNAMENT: 'INSERT_TOURNAMENT',
  SELECT_WINNER_FOR_MATCH: 'SELECT_WINNER_FOR_MATCH',
  SELECT_TEAM_RANKINKG: 'SELECT_TEAM_RANKINKG',
  DESELECT_WINNER_FOR_MATCH: 'DESELECT_WINNER_FOR_MATCH',
  DESELECT_TEAM_RANKING: 'DESELECT_TEAM_RANKING',
  SET_STATE: 'SET_STATE',
  SET_PREDICTED_GOALS: 'SET_PREDICTED_GOALS',
  SET_PREDICTED_CARDS: 'SET_PREDICTED_CARDS',
  SET_PREDICTED_CORNERS: 'SET_PREDICTED_CORNERS',
  SET_TOURNAMENT_PHASE: 'SET_TOURNAMENT_PHASE',
};

export default ActionTypes;
