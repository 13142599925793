export { default as VS } from './vs.svg';
export { default as V } from './v.svg';
export { default as X } from './x.svg';
export { default as activeBTN } from './activeBTN.svg';
export { default as FirstMedal } from './1st-medal.svg';
export { default as SecondMedal } from './2nd-medal.svg';
export { default as ThirdMedal } from './3rd-medal.svg';
export { ReactComponent as RankUp } from './RankUp.svg';
export { ReactComponent as RankDown } from './RankDown.svg';
export { ReactComponent as SuccessSvg } from './SuccessSvg.svg';
