import React, { useContext, useEffect, useRef, useState } from 'react';
import successSubmitVi from './images/successSubmitVi.png';
import axios, { CancelToken } from 'axios';
import { Check, PriorityHigh } from '@mui/icons-material';
import checkedVi from './images/smallVi.png';
import { Link, useNavigate } from 'react-router-dom';
import CircledVSvg from '../GeneralComponents/CircledVSvg';
import { SuccessSvg } from 'shared/assets/Decoration';
import './SubmissionSuccess.css';
import { AppSpinner, Button } from '@world-cup-client/ui';
import { TextField } from '@mui/material';
import { UserContext } from '../contexts/UserContext';
import { TournamentContext } from './contexts/TournamentContext';
import OutrightModal from '../Modal/OutrightModal/OutrightModal';
import { language } from '../state/language';
const headerColor = {
  background: 'transparent',
  // margin: 'auto',
};

const BASE_URL = process.env.NX_baseUrl;

const SubmissionSuccess = () => {
  const { user, refetch } = useContext(UserContext);
  const { showOutrightModal, setShowOutrightModal } =
    useContext(TournamentContext);

  const navigate = useNavigate();
  // states
  const [nickname, setNickname] = useState(null);
  const [error, setError] = useState(null);
  const [nicknameCheckLoading, setNicknameCheckLoading] = useState(false);
  const [loadingNicknameSave, setLoadingNicknameSave] = useState(false);
  const [isNicknameValid, setIsNicknameValid] = useState(null);
  // const { isMobile } = useWindowResize();
  const timeoutRef = useRef(null);
  const reqCancelRef = useRef(CancelToken.source());

  const savedToken = sessionStorage.getItem('token');
  const token =
    savedToken?.length && +savedToken !== 0 && savedToken !== 'null'
      ? savedToken
      : null;
  const cancelLastNicknameCheck = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (reqCancelRef.current?.cancel) {
      reqCancelRef.current.cancel();
      reqCancelRef.current = CancelToken.source();
    }
  };

  const submitNicknameHandler = async () => {
    try {
      if (user?.nickname) {
        return;
      }
      if (!nickname || error === 'Invalid nickname') return;
      setNicknameCheckLoading(false);
      setLoadingNicknameSave(true);
      cancelLastNicknameCheck();
      let userSelectedNickname;
      if (nickname?.length) {
        userSelectedNickname = nickname;
      }
      axios
        .post(
          `${BASE_URL}/api/set-nickname-to-user`,
          {
            hasNickname: true,
            nickname: userSelectedNickname,
          },
          {
            headers: {
              'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
              'jwt-token': token,
            },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            // navigate('/');
            // setShowOutrightModal(true);
            await refetch();
          }
        })
        .catch((err) => {
          console.error(err);
          setError('Error');
        });
      // setIsOpen(false);
      // if (typeof onSubmit === 'function') {
      // 	onSubmit();
      // }
      // let userSelectedNickname;
      // if (nickname?.length) {
      // 	userSelectedNickname = nickname;
      // }
      // await axios.post(`${BASE_URL}/api/set-nickname-to-user`, {
      // 	nickname: userSelectedNickname,
      // });
    } catch (err) {
      console.error(err);
      setError('Error');
    }
  };

  useEffect(() => {
    if (user?.nickname) return;
    setNicknameCheckLoading(false);
    if (!nickname || error === 'Invalid nickname') return;
    cancelLastNicknameCheck();
    timeoutRef.current = setTimeout(async () => {
      try {
        setNicknameCheckLoading(true);
        const {
          data: { isNicknameUnique },
        } = await axios.get(
          `${BASE_URL}/api/check-nickname-unique?nickname=${nickname}`,
          {
            headers: {
              'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
              'jwt-token': token,
            },
            cancelToken: reqCancelRef.current.token,
          }
        );
        setNicknameCheckLoading(false);
        setIsNicknameValid(isNicknameUnique);
        if (isNicknameUnique === false) {
          setError(language?.pages?.success?.nickname?.invalidNickname);
        }
      } catch (error) {
        console.error(error);
      }
    }, 500);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [error, nickname, token, user?.nickname]);

  // const eligibilityRef = useRef(sessionStorage.getItem("eligibility"));

  // const eligibility = eligibilityRef.current;
  // const notEligibleText = `¡Lo sentimos! No puedes optar al premio de esta promoción ya que tu cuenta no cumple con los requisitos legales obligatorios. Pero sí puedes un buen rato de diversión completando tus pronósticos de la Eurocopa y después apostar en cualquiera de los mercados disponibles. `;
  // const eligibleText = `¡Pronósticos listos! Seguro que lo petarás al máximo con tu porra de la Euro.`;

  return (
    <>
      <div
        style={
          user?.nickname && {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100vh',
          }
        }
      >
        <div style={headerColor} className="pb-3 text-center">
          <div
            className="text-center"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '70px',
            }}
          >
            <SuccessSvg />
          </div>
          <h1
            className="text-center"
            style={{
              color: 'rgba(220, 207, 147, 1)',
              fontSize: 22,
              marginTop: '10px',
            }}
          >
            {language?.pages?.success?.mainTitle1} <br />
            {language?.pages?.success?.mainTitle2}
          </h1>
        </div>
        {!user?.nickname && (
          <div
            className="container"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
              padding: '1.4rem',
              textAlign: 'center',
            }}
          >
            <div>
              <div className="nickname_title">
                {language?.pages?.success?.nickname?.mainTitle}
              </div>
              <div className="nickname_subtitle">
                {language?.pages?.success?.nickname?.desc}
              </div>
            </div>
            <TextField
              className="nickname_input"
              autoFocus
              sx={{
                '.MuiFormLabel-root.MuiInputLabel-root': {
                  color: '#75FBDA',
                },
                '& .MuiOutlinedInput-root': {
                  color: '#75FBDA',
                },
                '& .MuiOutlinedInput-input': {
                  textAlign: 'start !important',
                },
              }}
              disabled={loadingNicknameSave}
              label={language?.pages?.success?.nickname?.placeholder}
              variant="outlined"
              fullWidth
              error={!!error}
              value={nickname || ''}
              onChange={(e) => {
                setNickname(e.target.value);
                setIsNicknameValid(e.target.value === '' ? '' : null);
                !new RegExp(/^[A-Za-z][A-Za-z0-9]*$/).test(e.target.value)
                  ? setError(
                      language?.pages?.success?.nickname?.invalidNickname
                    )
                  : setError(null);
              }}
              type="text"
            />
            {nickname && (
              <div>
                {nicknameCheckLoading || isNicknameValid === null ? (
                  <AppSpinner color={'#353D49'} />
                ) : (
                  <div style={{ color: error ? '#EA336A' : '#119A78' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {error ? (
                        <PriorityHigh
                          style={{
                            color: '#000',
                            backgroundColor: '#EA336A',
                          }}
                          className="nickname_errorIcon"
                        />
                      ) : (
                        <Check
                          style={{
                            color: '#000',
                            backgroundColor: '#119A78',
                          }}
                          className="nickname_approvedIcon"
                        />
                      )}
                      {error && isNicknameValid !== null
                        ? error
                        : language?.pages?.success?.nickname?.validNickname}
                    </span>
                  </div>
                )}
              </div>
            )}
            <div className="nickname_noticeBox">
              <span>{language?.pages?.success?.nickname?.noticeMsg}</span>
            </div>
          </div>
        )}
        <Button
          className="nickname_submitBtn"
          disabled={
            user?.nickname
              ? false
              : error || nicknameCheckLoading || isNicknameValid === null
          }
          onClick={async () => {
            await submitNicknameHandler();
            setShowOutrightModal(true);
          }}
        >
          {language?.pages?.success?.nextBtn}
        </Button>
      </div>
      <OutrightModal
        isOpen={showOutrightModal}
        setIsOpen={setShowOutrightModal}
      />
    </>
  );
};
export default SubmissionSuccess;
