import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useWindowResize } from 'shared/hooks';
import Headline from '../Headline/Headline';
import TeamsBox from '../TeamsBox/TeamsBox';
import * as S from './GroupStage.styles';

export const GroupStage = ({ topContainerRef }) => {
  const queryClient = useQueryClient();
  const tournament = queryClient.getQueryData('tournament')?.tournament;
  const { stage } = useSelector(({ global }) => global);

  const groups = tournament?.groups;

  const { isMobile } = useWindowResize();

  useEffect(() => {
    if (topContainerRef?.current) {
      topContainerRef.current.scrollTo({
        behavior: 'smooth',
        block: 'start',
        top: 0,
        left: 0,
      });
    }
  }, [stage?.index, topContainerRef]);

  return (
    <S.GroupStage id="topContainer" $isMobile={isMobile}>
      <S.Header $isMobile={isMobile}>
        <Headline
          headline={'GROUP STAGE'}
          paragraph={`Please select which teams will end up 1st, 2nd, 3rd, and 4th`}
          editButton={true}
          completed={groups?.every(
            (g) => g.teams.filter((t) => t.selectedRanking)?.length === 4
          )}
          fromGroups
        />
      </S.Header>
      <S.TeamsBoxsWrapper>
        {/* <TeamsBox
          key={groups[0]?.id}
          groupId={groups[0]?.id}
          groupLetter={groups[0]?.displayName}
          teamsInGroup={groups[0]?.teams}
          GroupStageContainer
        /> */}
        {groups?.map((group) => {
          return (
            <TeamsBox
              key={group?.id}
              groupId={group?.id}
              groupLetter={group?.displayName}
              teamsInGroup={group?.teams}
              GroupStageContainer
            />
          );
        })}
      </S.TeamsBoxsWrapper>
    </S.GroupStage>
  );
};

export default GroupStage;
