import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import tournamentReducer from './../../state/tournament-reducer';
import { ActionCreators } from '../../state/action-creators';
import localForage from 'localforage';
import { setState } from '../../state/action-creators/action-creators';
import moment from 'moment';
import TournamentPhase from './../../enums/tournamentPhase';

const BASE_URL = process.env.NX_baseUrl;

export const TournamentContext = createContext();

export function TournamentProvider({ children }) {
  const initialState = {
    tournament: {
      teams: [],
      groups: [],
      rounds: [],
      predictedGoals: '',
      predictedCards: '',
      predictedCorners: '',
      startTime: null,
      tournamentPhase: null,
      isSubmissionExist: false,
      onlyKnockout: false,
    },
    putOrPost: null,
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [state, dispatch] = useReducer(tournamentReducer, initialState);
  const [loading, setLoading] = useState(true);
  const [stageSize, setStageSize] = useState('55rem');
  const [stageIndex, setStageIndex] = useState(0);
  const [postOrPut, setPostOrPut] = useState('post');
  const [enableEditing, setEnableEditing] = useState(true);
  const [userAddedPredictions, setUserAddedPredictions] = useState(null);
  const [odds, setOdds] = useState(null);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showOutrightModal, setShowOutrightModal] = useState(false);
  const [showClosedPlaceholder, setShowClosedPlaceholder] = useState(false);
  // const [showDisclaimerModal, setShowDisclaimerModal] = useState(null);
  const [colorsPalette, setColorsPalette] = useState({});
  const [tournamentEndOfGroupStage, setTournamentEndOfGroupStage] =
    useState(null);
  const [knockoutStageStartTime, setKnockoutStageStartTime] = useState(null);
  const sliderRef = useRef(null);

  const isAfterEndOfGroupStage = moment().isAfter(tournamentEndOfGroupStage);

  useEffect(() => {
    setColorsPalette({
      green: '#8bd606',
      black: '#000',
      yellow: '#ffd500',
      grey: '#46494b',
      secondaryGrey: 'rgba(77, 77, 77, 1)',
      darkGrey: '#181c1f',
      fadedGrey: 'rgba(52, 52, 52, 0.7)',
      brown: '#674003',
      gold: 'rgba(220, 207, 147, 1)',
    });
  }, []);

  const toggleEdit = useCallback(() => {
    setEnableEditing(!enableEditing);
  }, [enableEditing]);

  useEffect(() => {
    (async () => {
      try {
        const savedToken = sessionStorage.getItem('token');
        const token =
          savedToken?.length && +savedToken !== 0 && savedToken !== 'null'
            ? savedToken
            : null;
        const { data } = await axios.get(
          `${BASE_URL}/api/get-tournament-outrights-playtech-demo?playtechTypeId=${process.env.NX_PlaytechTypeId}`,
          {
            headers: {
              'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
              'jwt-token': token,
            },
          }
        );
        if (data?.outrights) {
          setOdds(data.outrights);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const savedToken = sessionStorage.getItem('token');
        const token =
          savedToken?.length && +savedToken !== 0 && savedToken !== 'null'
            ? savedToken
            : null;
        const savedState = await localForage.getItem('state');

        let tournament, submission;
        axios
          .get(
            `${BASE_URL}/api/get-tournament?tournamentId=77&clientId=${process.env.NX_ClientId}`,
            {
              headers: {
                'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
                'jwt-token': token,
              },
            }
          )
          .then((res) => {
            if (!res?.data) return;
            tournament = res?.data?.tournament;
            // const didTournamentStart = moment(tournament.startTime).isBefore(
            // 	moment()
            // );
            // if (didTournamentStart && !token) {
            //   navigate("/unauthorized");
            // }
            setTournamentEndOfGroupStage(moment(tournament?.groupStageEndTime));
            setKnockoutStageStartTime(
              moment(tournament?.knockoutStageStartTime).toDate()
            );
            if (!token) {
              return Promise.resolve(null);
            }
            return axios.get(
              `${BASE_URL}/api/get-tournament-submission?tournamentId=77&clientId=${process.env.NX_ClientId}`,
              {
                headers: {
                  'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
                  'jwt-token': token,
                },
              }
            );
          })
          .then((res) => {
            submission = res?.data?.submission;
            if (submission) {
              setPostOrPut('put');
              setEnableEditing(false);
              dispatch(
                ActionCreators.insertTournament({ tournament, submission })
              );
            } else {
              let tournamentPhase;

              if (moment(tournament.startTime).isAfter(moment())) {
                // the tournament has began
                tournamentPhase = TournamentPhase.beforeStart;
              } else if (
                moment(tournament.groupStageEndTime).isAfter(moment())
              ) {
                // group stage is in process
                tournamentPhase = TournamentPhase.groupStage;
              } else if (
                moment(tournament.knockoutStageStartTime).isAfter(moment()) &&
                moment().isAfter(moment(tournament.groupStageEndTime)) // before knockout stage has began and after group stage is over
              ) {
                tournamentPhase = TournamentPhase.beforeKnockout;
              } else if (!tournament.isFinished) {
                // tournament isn't finished yet
                tournamentPhase = TournamentPhase.knockoutStage;
              } else {
                // tournament ended
                tournamentPhase = TournamentPhase.tournamentEnded;
              }
              switch (tournamentPhase) {
                case TournamentPhase.groupStage:
                case TournamentPhase.knockoutStage:
                case TournamentPhase.tournamentEnded:
                  if (pathname.includes('leaderboard')) {
                    setShowClosedPlaceholder(false);
                  } else {
                    navigate('/');
                    setShowClosedPlaceholder(true);
                  }
                  return;
                case TournamentPhase.beforeStart:
                case TournamentPhase.beforeKnockout:
                  if (savedState && !savedState.knockoutPredictionsOnly) {
                    dispatch(setState(savedState));
                  } else {
                    dispatch(
                      ActionCreators.insertTournament({
                        tournament: { ...tournament, tournamentPhase },
                      })
                    );
                  }
                  break;
                default:
                  break;
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  }, [navigate, pathname]);

  const handleSubmit = async () => {
    const savedToken = sessionStorage.getItem('token');
    const token =
      savedToken?.length && +savedToken !== 0 && savedToken !== 'null'
        ? savedToken
        : null;

    if (token) {
      const tournamentPrediction = {
        tournamentId: state.tournament.id,
        groupPredictions: isAfterEndOfGroupStage
          ? null
          : Object.fromEntries(
              state.tournament.groups.map((g) => [
                g.name,
                Object.fromEntries(
                  g.teams
                    .map((t) => {
                      return [t?.selectedRanking?.toString(), t.id];
                    })
                    .filter((e) => !!e[0])
                ),
              ])
            ),
        matchPredictions: [
          ...state.tournament.rounds
            .map((r) => r.matches)
            .flat()
            .map((m) => ({
              matchId: m.id,
              result: m.selectedResult,
            })),
        ],
        onlyKnockout: Boolean(isAfterEndOfGroupStage),
      };
      if (postOrPut === 'put') {
        const res = await axios.put(
          `${BASE_URL}/api/edit-tournament-predictions`,
          tournamentPrediction,
          {
            headers: {
              'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
              'jwt-token': token,
            },
          }
        );
        if (res.status === 200) {
          setPostOrPut('put');
          console.log('submission was edited successfully!');
          navigate('/submission-success');
        }
      } else {
        const res = await axios.post(
          `${BASE_URL}/api/add-tournament-predictions`,
          tournamentPrediction,
          {
            headers: {
              'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
              'jwt-token': token,
            },
          }
        );
        if (res.status === 200) {
          setPostOrPut('put');
          console.log('submission sent!');
          navigate('/submission-success');
        }
      }
    } else {
      // // ! Only changed for the demo
      // await localForage.setItem('state', state);
      // setPostOrPut('put');
      // setEnableEditing(false);
      // setShowSubmitModal(postOrPut === 'post');
      loginHandler();

      return;
    }
  };

  const loginHandler = () => {
    Promise.all([localForage.setItem('state', state)]).then((res) => {
      const externalLoginUrl = process.env.NX_loginUrl;
      // const externalLoginUrl =
      //   'https://www.versus.es/loginmundial?happs=1&back_url=https://orenes-tst-admin.mhub2.com/en/mundial2022';

      window.location.assign(externalLoginUrl);
      window.top.location.href = externalLoginUrl;
      window.parent.location = externalLoginUrl;
      window.location.replace(externalLoginUrl);
    });
  };

  const gotoBetslip = async (outcomeToSend) => {
    try {
      const savedToken = sessionStorage.getItem('token');
      const token =
        savedToken?.length && +savedToken !== 0 && savedToken !== 'null'
          ? savedToken
          : null;
      const { data } = await axios.post(
        `${BASE_URL}/api/goto-betslip-versus`,
        { outcomes: [outcomeToSend] },
        {
          headers: {
            'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed',
            'jwt-token': token,
          },
        }
      );
      return data;
    } catch (err) {
      console.log('gotoBetslip', err);
    }
  };

  const formFilled =
    state.tournament.groups?.length &&
    state.tournament.groups.every((g) => {
      return g.teams.filter((t) => !!t.selectedRanking)?.length === 4;
    }) &&
    state.tournament.rounds?.length &&
    state.tournament.rounds?.every((r) =>
      r.matches.every((m) => !!m.selectedResult)
    );

  const value = {
    state,
    dispatch,
    odds,
    gotoBetslip,
    stageSize,
    setStageSize,
    stageIndex,
    setStageIndex,
    handleSubmit,
    postOrPut,
    enableEditing,
    toggleEdit,
    userAddedPredictions,
    loading,
    formFilled,
    setEnableEditing,
    sliderRef,
    showSubmitModal,
    setShowSubmitModal,
    showOutrightModal,
    setShowOutrightModal,
    isAfterEndOfGroupStage,
    colorsPalette,
    loginHandler,
    showClosedPlaceholder,
    setShowClosedPlaceholder,
    knockoutStageStartTime,
  };

  return (
    <TournamentContext.Provider value={value}>
      {children}
    </TournamentContext.Provider>
  );
}

async function getStateFromLocalStorage() {
  return {
    submission: await localForage.getItem('state'),
  };
}
