import { Modal, Fade, Backdrop } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { language } from '../../state/language';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    // backgroundColor: '#fff',
    // width: '80%',
    maxWidth: '400px',
    background:
      'linear-gradient(224.23deg, #EDDE9B 0%, #DBCB85 23.64%, #B7A96B 65.43%, #A89C63 100.88%)',
    borderRadius: '20px',
    border: '1px solid #000',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    padding: '16px 32px 24px',
    position: 'relative',
  },
  container_btn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '20px',
  },
  btn: {
    '&:active:not(:disabled)': {
      backgroundColor: '#fff',
      // background:
      //   'linear-gradient(224.23deg, #EDDE9B 0%, #DBCB85 23.64%, #B7A96B 65.43%, #A89C63 100.88%)',
      color: '#B4A669',
    },
    '&:disabled': {
      color: '#B4A669',
      borderColor: '#ebebeb',
      background:
        'linear-gradient(224.23deg, #EDDE9B 0%, #DBCB85 23.64%, #B7A96B 65.43%, #A89C63 100.88%)',
    },
    width: '80px',
    height: '30px',
    border: '1px solid #fff',
    borderRadius: '20px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));
const TournamentEditSubmitModal = ({ handleOk, handleClose, opened }) => {
  const [disabled, setDisabled] = useState(false);

  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={opened}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={opened}>
        <div className={classes.paper}>
          <p className="text-center" id="transition-modal-description">
            {language?.modals?.edit?.desc}
          </p>
          <div className={classes.container_btn}>
            <button
              disabled={disabled}
              onClick={() => {
                handleClose();
              }}
              className={classes.btn}
            >
              <span>{language?.modals?.edit?.noBtn}</span>
            </button>
            <button
              disabled={disabled}
              onClick={() => {
                setDisabled(true);
                handleOk().then(() =>
                  setTimeout(() => {
                    setDisabled(false);
                  }, 550)
                );
              }}
              className={classes.btn}
            >
              <span>{language?.modals?.edit?.yesBtn}</span>
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default TournamentEditSubmitModal;
