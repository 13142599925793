import { Backdrop, Fade, Modal } from '@mui/material';
import { ReactPortal } from '@world-cup-client/ui';
import { useWindowResize } from 'shared/hooks';
import * as S from './ClosedGroupStagePlaceholder.styles';
import BgImage from '../../images/general/ClosedGroupStagePlaceholderBg.png';
import { useContext } from 'react';
import { TournamentContext } from '../../Tournament/contexts/TournamentContext';
import { UserContext } from '../../contexts/UserContext';
import moment from 'moment';

const ClosedGroupStagePlaceholder = ({
  isOpen,
  setIsOpen,
  isKnockoutStage,
}) => {
  const { isMobile } = useWindowResize();
  const { knockoutStageStartTime } = useContext(TournamentContext);
  const hasKnockoutStageStarted = moment(knockoutStageStartTime).isBefore(
    moment()
  );

  return (
    <ReactPortal wrapperId="outright-modal">
      <Modal
        disablePortal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <S.PlaceholderWrapper $isMobile={isMobile}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <S.Content>
                <S.Title $isMobile={isMobile}>
                  {hasKnockoutStageStarted
                    ? 'el concurso ya esta cerrado'
                    : '¿Tardo, las predicciones so fron cerrado temporalmente?'}
                </S.Title>
                {!hasKnockoutStageStarted && (
                  <>
                    <S.Description>
                      El juego se abrirá de nuevo cuando se complete la fase de
                      grupos.
                    </S.Description>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <table
                        style={{ textAlign: 'start', position: 'relative' }}
                      >
                        <td style={{ padding: '10px' }}>
                          <S.TR>Apertura</S.TR>
                          <br />
                          <S.TR>Cierre</S.TR>
                        </td>
                        <div style={{ position: 'absolute' }}>
                          <S.vl />
                        </div>
                        <td style={{ padding: '10px' }}>
                          <S.TR> </S.TR>
                          <S.TR></S.TR>
                        </td>
                        <td style={{ padding: '10px' }}>
                          <S.TR>
                            Se volverá a abrir el juego el próximo 2 de
                            diciembre.
                          </S.TR>
                          <br />
                          <S.TR>
                            Se volverán a cerrar las predicciones 1 hora antes
                            del primer portido de Octavos
                          </S.TR>
                        </td>
                      </table>
                    </div>
                    <S.Description>
                      En resultados podrás ver tu progreso.
                    </S.Description>
                  </>
                )}
              </S.Content>
            </div>
          </S.PlaceholderWrapper>
        </Fade>
      </Modal>
    </ReactPortal>
  );
};

export default ClosedGroupStagePlaceholder;
