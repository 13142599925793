import * as S from './AppSpinner.styles';
import { useTheme } from 'styled-components';

export const AppSpinner = ({ color }) => {
	const theme = useTheme();
	return (
		<S.AppSpinner
			style={{ color: color ? color : theme?.primary }}
			animation="border"
		/>
	);
};
