import { Check, PriorityHigh } from '@mui/icons-material';
import { TextField } from '@mui/material';
import styled from 'styled-components';
import Button from '../../Button';

export const Paper = styled.div`
	position: absolute;
	width: ${({ $isMobile }) =>
		$isMobile ? 'calc(100% - 1.6rem)' : 'calc(50% - 1.6rem)'};
	/* background-color: #fff; */
	background: linear-gradient(100.46deg, #224881 6.42%, #4d678d 100%);
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%),
		0px 1px 14px 0px rgb(0 0 0 / 12%);
	border-radius: 15px;
	padding-top: 1rem;
	padding-bottom: 2rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: sans-serif;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ExitBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 1rem;
	width: 1rem;
	background-color: transparent;
	color: #fff;
	border: none;
`;

export const Title = styled.div`
	font-size: 1.6rem;
	color: #4f8cf2;
	font-weight: 600;
	margin-top: 30px;
`;

// export const Header = styled.div`
// 	/* background: linear-gradient(100.46deg, #224881 6.42%, #4d678d 100%); */
// 	width: 100%;
// 	/* margin: auto; */
// `;

export const Description = styled.div``;

export const TextInput = styled(TextField)`
	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 1rem;
	}
	fieldset {
		border-radius: 1rem;
	}
`;

export const StatusBoxWrapper = styled.div``;

export const StatusBoxContent = styled.div`
	color: ${({ error }) => (error ? '#E00000' : '#4F8CF2')};
`;

export const ApprovedIcon = styled(Check)`
	border-radius: 50%;
	background-color: #4f8cf2;
	color: white;
	margin-right: 0.6rem;
	height: 1.5rem;
	width: 1.5rem;
	padding: 0.1rem;
`;

export const ErrorIcon = styled(PriorityHigh)`
	border-radius: 50%;
	background-color: #e00000;
	color: white;
	margin-right: 0.6rem;
	height: 1.5rem;
	width: 1.5rem;
	padding: 0.1rem;
`;

export const NoticeBox = styled.div`
	color: rgba(53, 61, 73, 0.6);
	font-size: 0.9rem;
	line-height: 1.2rem;
`;

export const SubmitBtn = styled(Button)`
  background-color: #353d49;
  border: none;
  color: white;
  border-radius: 20px;
  margin: 0 auto;
  display: block;
  width: 15.6rem;
  height: 3rem;
  max-width: calc(100% - 1rem);
  &:disabled {
    background-color: rgba(53, 61, 73, 0.3);
  }
`;
